import env from "react-dotenv";
import axios from 'axios'

const AdminServices = {
    BaseUrl : env.REACT_APP_BASE_URL,
    ApiTokenKey : env.REACT_APP_API_TOKEN_KEY,
    data : [],
    api : axios.create({
        baseURL: env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
            'AuthorizationFrontWeb': env.REACT_APP_API_TOKEN_KEY, 
             Authorization: `Bearer ${localStorage.getItem('ccscts')}`
        }
    }),

    /*MODULO DE SESIONES */
    getSesionesCampana : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/sesiones_campana`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getSubsecciones  : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/subsecciones`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    storeSesionesCampana : async function(objSesion){
        let data  = '';
        try{
            await  this.api.post(`/api/post/create_sesion_campana`, objSesion).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;

    },
    
    getSesionesCampanaDetail : async function(SesionesId){
        let data  = '';
        try{
            await  this.api.get(`/api/get/edit_sesion_campana/${SesionesId}`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    updateSesionCampana : async function(ojbSesion, idSesion){
        let data  = '';
        try{
            await  this.api.put( `/api/put/update_sesion_campana/${idSesion}`, ojbSesion).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    statusSesionCampana : async function(objSesion){
        let data  = '';
        try{
            await  this.api.post(`/api/post/status_sesion_campana`, objSesion).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;        
    },

    deleteSesionCampana : async function(objSesion){
        let data  = '';
        try{
            await  this.api.post(`/api/post/delete_sesion_campana`, objSesion).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;   
    },
    /*FIN MODULO DE SESIONES */

    /*MODULO DE SUBSECCIONES */
    getSubSeccionesCampana : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/sub_secciones`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getCategoriasSubsecciones : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/categorias_subsecciones`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    storeSubsecciones : async function(objSubsecciones){
        let data  = '';
        try{
            await  this.api.post(`/api/post/create_sub_secciones`, objSubsecciones).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    updateSubsecciones : async function(objSubsecciones, idSubsecciones){
        let data  = '';
        try{
            await  this.api.put( `/api/put/update_sub_secciones/${idSubsecciones}`, objSubsecciones).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getSubSeccionesDetail : async function(SubSeccionesId){
        let data  = '';
        try{
            await  this.api.get(`/api/get/edit_sub_secciones/${SubSeccionesId}`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    statusSubsecciones : async function(objCategorias){
        let data  = '';
        try{
            await  this.api.post(`/api/post/status_sub_secciones`, objCategorias).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;        
    },

    deleteSubseccion : async function(objTpVisibilidad){
        let data  = '';
        try{
            await  this.api.post(`/api/post/delete_subseccion`, objTpVisibilidad).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;   
    },
    /*FIN MODULO DE SUBSECCIONES */ 

    /*MODULO DE CATEGORIAS */
    getCategorias : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/categorias`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    storeCategorias : async function(objCategorias){
        let data  = '';
        try{
            await  this.api.post(`/api/post/create_categorias`, objCategorias).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    UpdateCategorias : async function(ojbCategorias, idCategorias){
        let data  = '';
        try{
            await  this.api.put( `/api/put/update_categorias/${idCategorias}`, ojbCategorias).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getCategoriasDetail : async function(CategoriasId){
        let data  = '';
        try{
            await  this.api.get(`/api/get/edit_categorias/${CategoriasId}`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    statusCategorias : async function(objCategorias){
        let data  = '';
        try{
            await  this.api.post(`/api/post/status_categorias`, objCategorias).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;        
    },

    deleteCategorias : async function(objTpVisibilidad){
        let data  = '';
        try{
            await  this.api.post(`/api/post/delete_categorias`, objTpVisibilidad).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;   
    },
    /*FIN MODULO DE CATEGORIAS */
    
    /*MODULO DE VISIBILIDAD DE CAMPAÑAS */
    get_tpCampanaVisibilidad : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/visibilidad_campana`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getTpCampana : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/tipo_campana`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    storeTpVisibilidadCampana : async function(objTpCampana){
        let data  = '';
        try{
            await  this.api.post(`/api/post/create_tp_visibilidad_campana`, objTpCampana).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    updateTpVisibilidadCampana : async function(ojbTpCampana, idTpCampana){
        let data  = '';
        try{
            await  this.api.put( `/api/put/update_tp_visibilidad_campana/${idTpCampana}`, ojbTpCampana).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getTpCampanaVisibilidadDetail : async function(TpVisibilidadId){
        let data  = '';
        try{
            await  this.api.get(`/api/get/edit_tp_visibilidad_campana/${TpVisibilidadId}`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    statusTpCampanaVisibilidad : async function(objTpVisibilidad){
        let data  = '';
        try{
            await  this.api.post(`/api/post/status_tp_campana_visibilidad`, objTpVisibilidad).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;        
    },

    deleteTpVisibilidad : async function(objTpVisibilidad){
        let data  = '';
        try{
            await  this.api.post(`/api/post/delete_tp_visibilidad`, objTpVisibilidad).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;   
    },
    /*FIN MODULO DE VISIBILIDAD DE CAMPAÑAS */

    /*MODULO DE TIPO DE CAMPAÑAS */
    get_tpCampanas : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/tp_campanas`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getTpElementos  : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/tp_elementos`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    storeTpCampana : async function(objTpCampana){
        let data  = '';
        try{
            await  this.api.post(`/api/post/create_tp_campana`, objTpCampana).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    updateTpCampana : async function(ojbTpCampana, idTpCampana){
        let data  = '';
        try{
            await  this.api.put( `/api/put/update_tp_campana/${idTpCampana}`, ojbTpCampana).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getTpCampanaDetail : async function(TpCampanaId){
        let data  = '';
        try{
            await  this.api.get(`/api/get/edit_tp_campana/${TpCampanaId}`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    statusTpCampana : async function(objTpCampana){
        let data  = '';
        try{
            await  this.api.post(`/api/post/status_tp_campana`, objTpCampana).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;        
    },

    deleteTpCampana : async function(objElemento){
        let data  = '';
        try{
            await  this.api.post(`/api/post/delete_tp_campana`, objElemento).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;   
    },
    /*FIN MODULO DE TIPO DE CAMPAÑAS */

    /*MODULO DE ELEMENTOS */
    getElementos : async function(){
        let data  = '';
        try{
            await  this.api.get(`/api/get/elementos`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    storeElemento : async function(objElemento){
        let data  = '';
        try{
            await  this.api.post(`/api/post/create_elemento`, objElemento).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    updateElementos : async function(ojbElemento, idElemento){
        let data  = '';
        try{
            await  this.api.put( `/api/put/update_elemento/${idElemento}`, ojbElemento).then(response => {
                data = response;
            })
            this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    getElementosDetail : async function(ElementosId){
        let data  = '';
        try{
            await  this.api.get(`/api/get/edit_elemento/${ElementosId}`).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;
    },

    statusElemento : async function(objElemento){
        let data  = '';
        try{
            await  this.api.post(`/api/post/status_elemento`, objElemento).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;        
    },

    deleteElementos : async function(objElemento){
        let data  = '';
        try{
            await  this.api.post(`/api/post/delete_elemento`, objElemento).then(response => {
                data = response;
            })
        this.data = data.data;
        }catch(error){
            data = error;
            this.data.error = error;
        }
        return data;   
    },
    /*FIN MODULO DE ELEMENTOS */
    
}
export default AdminServices;