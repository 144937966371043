import React, { Component } from 'react';
import ModalLg from '../../ModalLg';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default class VisibilidadCampanasNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSubseccion: '',
            subseccionesSeleccionadas: [],
            subseccionesDisplay: []
        };
    }

    componentDidMount() {
        this.initializeSubsecciones();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.stateFather.subseccionesSeleccionadas !== this.props.stateFather.subseccionesSeleccionadas) {
            this.initializeSubsecciones();
        }
    }

    initializeSubsecciones = () => {
        const { stateFather } = this.props;
    
        if (stateFather.subseccionesSeleccionadas && Array.isArray(stateFather.subseccionesSeleccionadas)) {
            let subseccionesIds = [];
            let subseccionesDisplay = [];
    
            // Validar si los elementos son objetos o solo IDs
            if (typeof stateFather.subseccionesSeleccionadas[0] === "object") {
                subseccionesIds = stateFather.subseccionesSeleccionadas.map(sub => sub.id.toString());
                subseccionesDisplay = stateFather.subseccionesSeleccionadas.map(sub => ({
                    id: sub.id.toString(),
                    nombre: sub.nombre_subseccion
                }));
            } else {
                subseccionesIds = stateFather.subseccionesSeleccionadas;
                subseccionesDisplay = subseccionesIds.map(id => {
                    const subData = stateFather.listaTiposTpCampana.find(tipo => tipo.id.toString() === id);
                    return subData ? { id: id, nombre: subData.nombre } : { id: id, nombre: "Subsección no encontrada" };
                });
            }
    
            this.setState({
                subseccionesSeleccionadas: subseccionesIds,
                subseccionesDisplay
            });
        }
    };
    

    handleSubseccionChange = (e) => {
        this.setState({ selectedSubseccion: e.target.value });
    };

    handleAddSubseccion = () => {
        const { selectedSubseccion, subseccionesSeleccionadas, subseccionesDisplay } = this.state;
        const { stateFather } = this.props;
    
        if (selectedSubseccion) {
            // Buscar el objeto de la subsección en listaTipos
            const subseccionObj = stateFather.listaTiposTpCampana.find(
                tipo => tipo.id.toString() === selectedSubseccion
            );
    
            if (subseccionObj && !subseccionesSeleccionadas.includes(selectedSubseccion)) {
                const nuevasSubsecciones = [...subseccionesSeleccionadas, selectedSubseccion];
                const nuevasSubseccionesDisplay = [...subseccionesDisplay, {
                    id: selectedSubseccion,
                    nombre: subseccionObj.nombre // Ahora sí obtenemos el nombre correcto
                }];
    
                this.setState({
                    subseccionesSeleccionadas: nuevasSubsecciones,
                    subseccionesDisplay: nuevasSubseccionesDisplay,
                    selectedSubseccion: ''
                }, () => {
                    console.log("Subsecciones seleccionadas actualizadas:", this.state.subseccionesSeleccionadas);
                    this.props.onSubseccionesChange(nuevasSubsecciones); // Enviamos solo los IDs al padre
                });
            }
        }
    };

    handleRemoveSubseccion = (index) => {
        this.setState(prevState => {
            const nuevasSubsecciones = [...prevState.subseccionesSeleccionadas];
            const nuevasSubseccionesDisplay = [...prevState.subseccionesDisplay];

            nuevasSubsecciones.splice(index, 1);
            nuevasSubseccionesDisplay.splice(index, 1);

            this.props.onSubseccionesChange(nuevasSubsecciones);

            return {
                subseccionesSeleccionadas: nuevasSubsecciones,
                subseccionesDisplay: nuevasSubseccionesDisplay
            };
        });
    };

    handleClose = () => {
        console.log('Cerrando modal y limpiando variables');
        this.props.deleteVariables(); // Primero limpiamos las variables
        this.props.onClose(); // Luego cerramos el modal
    }

    render() {
        const propsInt = this.props;
        const stateFather = this.props.stateFather;
        const { selectedSubseccion, subseccionesDisplay } = this.state;

        return (
            <ModalLg isOpen={propsInt.isOpen} onClose={propsInt.onClose} modalTitle={propsInt.modalTitleVisibilidad}>
                <div className="modal fade" id="modal-lg" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title font-weight-bold">{propsInt.modalTitleVisibilidad}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span onClick={this.handleClose} aria-hidden="true">×</span>
                                </button>
                            </div>
                            <LoadingOverlay active={stateFather.loadingModalEdit} spinner text='Cargando contenido...'>
                                <form onSubmit={propsInt.handleSubmitVisibilidad}>
                                    <div className="modal-body">
                                        <div className="d-flex">
                                            {/* Left Column */}
                                            <div className="w-50 pr-3">
                                                <div className="form-group">
                                                    <label>Nombre:</label>
                                                    <input
                                                        type="text"
                                                        value={stateFather.name}
                                                        onChange={propsInt.handleChangeI}
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Nombre Sección"
                                                    />
                                                    {stateFather.errorsForm.name.length > 0 && (
                                                        <span className="error error-class-i">{stateFather.errorsForm.name}</span>
                                                    )}
                                                </div>
                                            </div>
                                            {/* Right Column */}
                                            <div className="w-50 pl-3">
                                                <div className="form-group">
                                                    <label>Tipo de Campaña dependientes*</label>
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <select
                                                                className="form-control"
                                                                value={selectedSubseccion}
                                                                onChange={this.handleSubseccionChange}
                                                            >
                                                                <option value="">Seleccionar</option>
                                                                {stateFather.listaTiposTpCampana.map((tipo) => (
                                                                    <option key={tipo.id} value={tipo.id}>
                                                                        {tipo.nombre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary ml-2 btn-add"
                                                            onClick={this.handleAddSubseccion}
                                                            style={{fontSize:"1.85rem", padding:".2rem 1rem", lineHeight:"1", fontWeight:"400"}}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="p-2 mb-2"
                                                style={{ 
                                                    borderBottom: '1px solid #383838',
                                                    fontWeight: 'bold',
                                                    color: '#383838'
                                                }}
                                                >Nombre</div>
                                                {subseccionesDisplay.length > 0 && (
                                                    <div className="mt-2 mb-2">
                                                        {subseccionesDisplay.map((subseccion, index) => (
                                                            <div 
                                                                key={index} 
                                                                className="d-flex align-items-center justify-content-between p-2 mb-2"
                                                                style={{ 
                                                                    border:'transparent',
                                                                    borderBottom: '1px solid #dee2e6'
                                                                }}
                                                            >
                                                                <div className="w-100">
                                                                    <label className="text-muted mb-0" style={{ display: 'none'  }}></label>
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <div className="">
                                                                            {subseccion.nombre}
                                                                        </div>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-link text-danger p-0"
                                                                            onClick={() => this.handleRemoveSubseccion(index)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="modal-footer row">
                                            <div className="col-12 d-flex justify-content-between">
                                                <button type="button" onClick={this.handleClose} className="btn btn-outline-secondary" data-dismiss="modal">
                                                    Cerrar
                                                </button>
                                                <button type="submit" onClick={propsInt.handleClickBs} className="btn btn-primary">
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>
            </ModalLg>
        );
    }
}
