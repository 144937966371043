import React, { Component } from 'react';
import ElementosNew from './ElementosNew';
import SesionesNew from './SesionesNew';
import SesionesShow from './SesionesShow';
import ElementosShow from './ElementosShow'
import TpCampanasNew from './TpCampanasNew';
import SubSeccionesNew from './SubSeccionesNew';
import VisibilidadCampanasNew from './VisibilidadCampanasNew';
import CategoriasNew from './CategoriasNew';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import LoadingOverlay from 'react-loading-overlay';
import {hasPermission} from '../../../util/auth';
import NotAuthorized from '../../layout/NotAuthorized';
export default class CampanasSesiones extends Component {
    state = {
        selectedSubseccion: '',
        subseccionesSeleccionadas: [],
        listaTipos: [
            { nombre: 'Opción 1' },
            { nombre: 'Opción 2' },
            // ... más opciones
        ],
        errorsForm: {
            name: ''
        },
        name: '',
        loadingModalEdit: false,
        currentModal: ''
    };

    
    render() {
        const dataAll = this.props.state; 
        const propsInt = this.props;
        console.log('propsInt currentModal', this.props.currentModal);
        console.log('state currentModal', this.state.currentModal);
        const currentModal = this.state.currentModal || this.props.currentModal;
        console.log('currentModal consultado', currentModal);
        const { SearchBar } = Search;
        const optionsElementos = {
            custom: true,
            paginationSize: 5,
            pageStartIndex: 1,
            firstPageText: 'Primera Página',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Final',
            nextPageTitle: 'Inicio Página',
            prePageTitle: 'Página Anterior',
            firstPageTitle: 'Siguiente Página',
            lastPageTitle: 'Anterior Página',
            totalSize: dataAll.dataElementos.length,
            sizePerPageList :[ {
                text: '10', value: 10
                }, {
                text: '5', value: 5
                }, {
                text: 'All', value: dataAll.dataElementos.length
                }
            ]
        };

        const optionsSesiones = {
            custom: true,
            paginationSize: 5,
            pageStartIndex: 1,
            firstPageText: 'Primera Página',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Final',
            nextPageTitle: 'Inicio Página',
            prePageTitle: 'Página Anterior',
            firstPageTitle: 'Siguiente Página',
            lastPageTitle: 'Anterior Página',
            totalSize: dataAll.dataSesiones.length,
            sizePerPageList :[ {
                text: '10', value: 10
                }, {
                text: '5', value: 5
                }, {
                text: 'All', value: dataAll.dataSesiones.length
                }
            ]
        };

        const optionsTpCampanas = {
            custom: true,
            paginationSize: 5,
            pageStartIndex: 1,
            firstPageText: 'Primera Página',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Final',
            nextPageTitle: 'Inicio Página',
            prePageTitle: 'Página Anterior',
            firstPageTitle: 'Siguiente Página',
            lastPageTitle: 'Anterior Página',
            totalSize: dataAll.dataTpCampanas.length,
            sizePerPageList :[ {
                text: '10', value: 10
                }, {
                text: '5', value: 5
                }, {
                text: 'All', value: dataAll.dataTpCampanas.length
                }
            ]
        };

        const optionsTpCampanaVisibilidad = {
            custom: true,
            paginationSize: 5,
            pageStartIndex: 1,
            firstPageText: 'Primera Página',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Final',
            nextPageTitle: 'Inicio Página',
            prePageTitle: 'Página Anterior',
            firstPageTitle: 'Siguiente Página',
            lastPageTitle: 'Anterior Página',
            totalSize: dataAll.dataTpCampanaVisibilidad.length,
            sizePerPageList :[ {
                text: '10', value: 10
                }, {
                text: '5', value: 5
                }, {
                text: 'All', value: dataAll.dataTpCampanaVisibilidad.length
                }
            ]
        };

        const optionsSubSecciones = {
            custom: true,
            paginationSize: 5,
            pageStartIndex: 1,
            firstPageText: 'Primera Página',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Final',
            nextPageTitle: 'Inicio Página',
            prePageTitle: 'Página Anterior',
            firstPageTitle: 'Siguiente Página',
            lastPageTitle: 'Anterior Página',
            totalSize: dataAll.dataSubSecciones.length,
            sizePerPageList :[ {
                text: '10', value: 10
                }, {
                text: '5', value: 5
                }, {
                text: 'All', value: dataAll.dataSubSecciones.length
                } ]
        };
        const optionsCategorias = {
            custom: true,
            paginationSize: 5,
            pageStartIndex: 1,
            firstPageText: 'Primera Página',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Final',
            nextPageTitle: 'Inicio Página',
            prePageTitle: 'Página Anterior',
            firstPageTitle: 'Siguiente Página',
            lastPageTitle: 'Anterior Página',
            totalSize: dataAll.dataCategorias.length,
            sizePerPageList :[ {
                text: '10', value: 10
                }, {
                text: '5', value: 5
                }, {
                text: 'All', value: dataAll.dataCategorias.length
                } ]
        };
        const contentTableCategorias = ({ paginationProps, paginationTableProps }) => (
            <div>
                <ToolkitProvider keyField="id" columns={ dataAll.columnConfig.categorias } data={ dataAll.dataCategorias } search >
                {toolkitprops => (
                    <div>
                        <SearchBar { ...toolkitprops.searchProps }  placeholder='Buscar' />
                        <BootstrapTable striped hover { ...toolkitprops.baseProps } { ...paginationTableProps }/>
                    </div>
                    )
                }
                </ToolkitProvider>
                <PaginationListStandalone { ...paginationProps } />
            </div>
        );
        const contentTableSubSecciones = ({ paginationProps, paginationTableProps }) => (
            <div>
              <ToolkitProvider keyField="id" columns={ dataAll.columnConfig.sub_secciones } data={ dataAll.dataSubSecciones } search >
                {toolkitprops => (
                    <div>
                      <SearchBar { ...toolkitprops.searchProps }  placeholder='Buscar' />
                      <BootstrapTable striped hover { ...toolkitprops.baseProps } { ...paginationTableProps }/>
                    </div>
                  )
                }
              </ToolkitProvider>
              <PaginationListStandalone { ...paginationProps } />
            </div>
        );

        const contentTableSesiones = ({ paginationProps, paginationTableProps }) => (
            <div>
              <ToolkitProvider keyField="id" columns={ dataAll.columnConfig.secciones } data={ dataAll.dataSesiones } search >
                {toolkitprops => (
                    <div>
                      <SearchBar { ...toolkitprops.searchProps }  placeholder='Buscar' />
                      <BootstrapTable striped hover { ...toolkitprops.baseProps } { ...paginationTableProps }/>
                    </div>
                  )
                }
              </ToolkitProvider>
              <PaginationListStandalone { ...paginationProps } />
            </div>
        );    
        const contentTableTpCampanaVisibilidad = ({ paginationProps, paginationTableProps }) => (
            <div>
              <ToolkitProvider keyField="id" columns={ dataAll.columnConfig.visibilidad } data={ dataAll.dataTpCampanaVisibilidad } search >
                {toolkitprops => (
                    <div>
                      <SearchBar { ...toolkitprops.searchProps }  placeholder='Buscar' />
                      <BootstrapTable striped hover { ...toolkitprops.baseProps } { ...paginationTableProps }/>
                    </div>
                  )
                }
              </ToolkitProvider>
              <PaginationListStandalone { ...paginationProps } />
            </div>
        );  

        const contentTableTpCampanas = ({ paginationProps, paginationTableProps }) => (
            <div>
              <ToolkitProvider keyField="id" columns={ dataAll.columnConfig.tipo_campana } data={ dataAll.dataTpCampanas } search >
                {toolkitprops => (
                    <div>
                      <SearchBar { ...toolkitprops.searchProps }  placeholder='Buscar' />
                      <BootstrapTable striped hover { ...toolkitprops.baseProps } { ...paginationTableProps }/>
                    </div>
                  )
                }
              </ToolkitProvider>
              <PaginationListStandalone { ...paginationProps } />
            </div>
        );    
        const contentTableElementos = ({ paginationProps, paginationTableProps }) => (
            <div>
              <ToolkitProvider keyField="id" columns={ dataAll.columnConfig.elementos } data={ dataAll.dataElementos } search >
                {toolkitprops => (
                    <div>
                      <SearchBar { ...toolkitprops.searchProps }  placeholder='Buscar' />
                      <BootstrapTable striped hover { ...toolkitprops.baseProps } { ...paginationTableProps }/>
                    </div>
                  )
                }
              </ToolkitProvider>
              <PaginationListStandalone { ...paginationProps } />
            </div>
        );        
        if(dataAll.error){
            return `Error ${this.props.state.error} `;
        }
        return (
            <React.Fragment>
                <div className="col-10 tabs">
                {hasPermission([11012,11017,11022,11027,11032,11037]) ?
                  <div>
                    <h2 className="mt-4 mb-4">Campañas Secciones</h2>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                        <a className="nav-link active bg-tab01" id="secciones-tab" data-toggle="tab" href="#secciones" role="tab" aria-controls="secciones" aria-selected="true">Secciones</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link bg-tab01" id="subsecciones-tab" data-toggle="tab" href="#subsecciones" role="tab" aria-controls="subsecciones" aria-selected="false">Sub secciones</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link bg-tab01" id="categorias-tab" data-toggle="tab" href="#categorias" role="tab" aria-controls="categorias" aria-selected="false">Categorías</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link bg-tab02" id="visibilidad-tab" data-toggle="tab" href="#visibilidad" role="tab" aria-controls="visibilidad" aria-selected="false">Visibilidad de campaña</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link bg-tab02" id="tipo-campana-tab" data-toggle="tab" href="#tipo-campana" role="tab" aria-controls="tipo-campana" aria-selected="false">Tipo campaña</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link bg-tab02" id="elementos-tab" data-toggle="tab" href="#elementos" role="tab" aria-controls="elementos" aria-selected="false">Elementos</a>
                        </li>
                    </ul>
                    <LoadingOverlay active={dataAll.loading} spinner text='Cargando contenido...' >
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="secciones" role="tabpanel" aria-labelledby="secciones-tab">
                            <div className="">
                                {hasPermission([11013]) &&
                                    <button 
                                        onClick={() => {
                                            propsInt.newOrEditSesiones(0, 'seccion');
                                            this.setState({ currentModal: 'seccion' });
                                        }} 
                                        type="button" 
                                        className="btn btn-primary"
                                        data-toggle="modal" 
                                        data-target="#modal-lg"

                                        style={{ 
                                            float: "right"
                                        }}
                                    >
                                        Crear Sección
                                    </button>
                                }
                            </div>
                            <PaginationProvider pagination={paginationFactory(optionsSesiones)}>
                                { contentTableSesiones }
                            </PaginationProvider>
                        </div>
                        <div className="tab-pane fade" id="subsecciones" role="tabpanel" aria-labelledby="subsecciones-tab">
                            <div className="">
                                {hasPermission([11018]) &&
                                    <button 
                                        onClick={() => {
                                            propsInt.newOrEditSesiones(0, 'subseccion');
                                            this.setState({ currentModal: 'subseccion' });
                                        }} 
                                        type="button" 
                                        className="btn btn-primary"
                                        data-toggle="modal" 
                                        data-target="#modal-lg"

                                        style={{ 
                                            float: "right"
                                        }}
                                    >
                                        Crear Subsección
                                    </button>
                                }
                            </div>
                            <PaginationProvider pagination={paginationFactory(optionsSubSecciones)}>
                                { contentTableSubSecciones }
                            </PaginationProvider>
                        </div>
                        <div className="tab-pane fade" id="categorias" role="tabpanel" aria-labelledby="categorias-tab">
                            <div className="">
                                {hasPermission([11023]) &&
                                    <button 
                                        onClick={() => {
                                            propsInt.newOrEditSesiones(0, 'categoria');
                                            this.setState({ currentModal: 'categoria' });
                                        }} 
                                        type="button" 
                                        className="btn btn-primary"
                                        data-toggle="modal" 
                                        data-target="#modal-lg"

                                        style={{ 
                                            float: "right"
                                        }}
                                    >
                                        Crear Categoría
                                    </button>
                                }
                            </div>
                            <PaginationProvider pagination={paginationFactory(optionsCategorias)}>
                                { contentTableCategorias }
                            </PaginationProvider>
                        </div>
                        <div className="tab-pane fade" id="visibilidad" role="tabpanel" aria-labelledby="visibilidad-tab">
                            <div className="">
                                {hasPermission([11028]) &&
                                    <button 
                                        onClick={() => {
                                            propsInt.newOrEditSesiones(0, 'visibilidad');
                                            this.setState({ currentModal: 'visibilidad' });
                                        }} 
                                        type="button" 
                                        className="btn btn-primary"
                                        data-toggle="modal" 
                                        data-target="#modal-lg"

                                        style={{ 
                                            float: "right"
                                        }}
                                    >
                                        Crear Visibilidad
                                    </button>
                                }
                            </div>
                            <PaginationProvider pagination={paginationFactory(optionsTpCampanaVisibilidad)}>
                                { contentTableTpCampanaVisibilidad }
                            </PaginationProvider>
                        </div>
                        <div className="tab-pane fade" id="tipo-campana" role="tabpanel" aria-labelledby="tipo-campana-tab">
                            <div className="">
                                {hasPermission([11033]) &&
                                    <button 
                                        onClick={() => {
                                            propsInt.newOrEditSesiones(0, 'tipo-campana');
                                            this.setState({ currentModal: 'tipo-campana' });
                                        }} 
                                        type="button" 
                                        className="btn btn-primary"
                                        data-toggle="modal" 
                                        data-target="#modal-lg"

                                        style={{ 
                                            float: "right"
                                        }}
                                    >
                                        Crear Tipo Campaña
                                    </button>
                                }
                            </div>
                            <PaginationProvider pagination={paginationFactory(optionsTpCampanas)}>
                                { contentTableTpCampanas }
                            </PaginationProvider>
                        </div>
                        <div className="tab-pane fade" id="elementos" role="tabpanel" aria-labelledby="elementos-tab">
                            <div className="">
                                {hasPermission([11038]) &&
                                    <button 
                                        onClick={() => {
                                            propsInt.newOrEditSesiones(0, 'elemento');
                                            this.setState({ currentModal: 'elemento' });
                                        }} 
                                        type="button" 
                                        className="btn btn-primary"
                                        data-toggle="modal" 
                                        data-target="#modal-lg"

                                        style={{ 
                                            float: "right"
                                        }}
                                    >
                                        Crear Elemento
                                    </button>
                                }
                            </div>
                            <PaginationProvider pagination={paginationFactory(optionsElementos)}>
                                { contentTableElementos }
                            </PaginationProvider>
                        </div>
                    </div>
                    </LoadingOverlay>
                    </div>
                        :<NotAuthorized></NotAuthorized>
                    }
    
                    {currentModal === 'seccion' && (
                        <SesionesNew
                        stateFather={propsInt.state}
                        isOpen={propsInt.modalIsopen} 
                        onClose={() => {
                            propsInt.onCloseModal();
                            this.setState({ currentModal: '' });
                        }}
                        modalCreateTitle = {propsInt.modalCreateTitle}
                        handleChangeI={propsInt.handleChangeI}
                        handleSubmitBs={propsInt.handleSubmitBs}
                        modalTitle={propsInt.modalTitle}
                        onSelectTipos={propsInt.onSelectTipos}
                        onRemoveTipos={propsInt.onRemoveTipos}
                        handleSubseccionChange={this.handleSubseccionChange}
                        handleAddSubseccion={this.handleAddSubseccion}
                        handleSubseccionNombreChange={this.handleSubseccionNombreChange}
                        handleRemoveSubseccion={this.handleRemoveSubseccion}
                        onSubseccionesChange={propsInt.handleSubseccionesChange} 
                        deleteVariables = {propsInt.deleteVariables}
                        modalTitleSesiones={propsInt.modalTitleSesiones}
                        >                            
                        </SesionesNew>
                    )}
                    
                    {currentModal === 'subseccion' && (
                        <SubSeccionesNew 
                            stateFather={propsInt.state}
                            isOpen={propsInt.modalIsopen} 
                            onClose={() => {
                                console.log('vino a onCloseModal');
                                propsInt.onCloseModal();
                                this.setState({ currentModal: null });
                            }}
                            modalCreateTitle={propsInt.modalCreateTitle}
                            handleChangeI={propsInt.handleChangeI}
                            handleSubmitBs={propsInt.handleSubmitBs}
                            modalTitle={propsInt.modalTitle}
                            deleteVariables={propsInt.deleteVariables}
                            onSelectTipos={propsInt.onSelectTipos}
                            onRemoveTipos={propsInt.onRemoveTipos}
                            handleSubseccionChange={this.handleSubseccionChange}
                            handleAddSubseccion={this.handleAddSubseccion}
                            handleSubseccionNombreChange={this.handleSubseccionNombreChange}
                            handleRemoveSubseccion={this.handleRemoveSubseccion}
                            onSubseccionesChange={propsInt.handleSubseccionesChange} 
                            handleSubmitSubsecciones={propsInt.handleSubmitSubsecciones}
                            modalTitleSubsecciones={propsInt.modalTitleSubsecciones}
                        />
                    )}

                    {currentModal === 'categoria' && (
                        <CategoriasNew 
                            stateFather={propsInt.state}
                            isOpen={propsInt.modalIsopen} 
                            onClose={() => {
                                propsInt.onCloseModal();
                                this.setState({ currentModal: null });
                            }}
                            modalCreateTitle={propsInt.modalCreateTitle}
                            handleChangeI={propsInt.handleChangeI}
                            handleSubmitBs={propsInt.handleSubmitBs}
                            modalTitle={propsInt.modalTitle}
                            deleteVariables={propsInt.deleteVariables}
                            onSelectTipos={propsInt.onSelectTipos}
                            onRemoveTipos={propsInt.onRemoveTipos}
                            handleSubseccionChange={this.handleSubseccionChange}
                            handleAddSubseccion={this.handleAddSubseccion}
                            handleSubseccionNombreChange={this.handleSubseccionNombreChange}
                            handleRemoveSubseccion={this.handleRemoveSubseccion}
                            onSubseccionesChange={propsInt.handleSubseccionesChange} 
                            handleSubmitCategorias={propsInt.handleSubmitCategorias}
                            modalTitleCategorias={propsInt.modalTitleCategorias}
                        />
                    )}

                    {currentModal === 'visibilidad' && (
                        <VisibilidadCampanasNew
                            stateFather={propsInt.state}
                            isOpen={propsInt.modalIsopen} 
                            onClose={() => {
                                propsInt.onCloseModal();
                                this.setState({ currentModal: null });
                            }}
                            modalCreateTitle={propsInt.modalCreateTitle}
                            handleChangeI={propsInt.handleChangeI}
                            handleSubmitVisibilidad={propsInt.handleSubmitVisibilidad}
                            modalTitle={propsInt.modalTitle}
                            deleteVariables={propsInt.deleteVariables}
                            onSelectTipos={propsInt.onSelectTipos}
                            onRemoveTipos={propsInt.onRemoveTipos}
                            handleSubseccionChange={this.handleSubseccionChange}
                            handleAddSubseccion={this.handleAddSubseccion}
                            handleSubseccionNombreChange={this.handleSubseccionNombreChange}
                            handleRemoveSubseccion={this.handleRemoveSubseccion}
                            onSubseccionesChange={propsInt.handleSubseccionesChange} 
                            modalTitleVisibilidad={propsInt.modalTitleVisibilidad}
                        />
                    )}

                    {currentModal === 'tipo-campana' && (
                        <TpCampanasNew
                            stateFather={propsInt.state}
                            isOpen={propsInt.modalIsopen} 
                            onClose={() => {
                                propsInt.onCloseModal();
                                this.setState({ currentModal: null });
                            }}
                            modalCreateTitle={propsInt.modalCreateTitle}
                            handleChangeI={propsInt.handleChangeI}
                            handleSubmitTpCampana={propsInt.handleSubmitTpCampana}
                            modalTitle={propsInt.modalTitle}
                            deleteVariables={propsInt.deleteVariables}
                            onSelectTipos={propsInt.onSelectTipos}
                            onRemoveTipos={propsInt.onRemoveTipos}
                            handleSubseccionChange={this.handleSubseccionChange}
                            handleAddSubseccion={this.handleAddSubseccion}
                            handleSubseccionNombreChange={this.handleSubseccionNombreChange}
                            handleRemoveSubseccion={this.handleRemoveSubseccion}
                            onSubseccionesChange={propsInt.handleSubseccionesChange} 
                            modalTitleTpCampanas={propsInt.modalTitleTpCampanas}
                        />
                    )}

                    {currentModal === 'elemento' && (
                        <ElementosNew 
                            stateFather={propsInt.state}
                            isOpen={propsInt.modalIsopen} 
                            onClose={() => {
                                propsInt.onCloseModal();
                                this.setState({ currentModal: null });
                            }}
                            modalCreateTitle={propsInt.modalCreateTitle}
                            handleChangeI={propsInt.handleChangeI}
                            handleSubmitElemento={propsInt.handleSubmitElemento}
                            modalTitle={propsInt.modalTitle}
                            deleteVariables={propsInt.deleteVariables}
                            modalTitleElementos={propsInt.modalTitleElementos}
                        />
                    )}
                    <SesionesShow
                        stateFather={propsInt.state}
                        isOpen={propsInt.modalIsopen} 
                        onClose={propsInt.onCloseModal}
                        modalCreateTitle={propsInt.modalCreateTitle}
                        handleChangeI={propsInt.handleChangeI}
                        handleSubmitBs={propsInt.handleSubmitBs}
                        modalTitle={propsInt.modalTitle}
                        deleteVariables={propsInt.deleteVariables}
                        deleteSesion={propsInt.deleteSesion}
                    >                            
                    </SesionesShow>
                  </div>
            </React.Fragment>
        )
    }
}
