import React, { Component } from 'react';
import ModalLg from '../../ModalLg';
//import { Multiselect } from 'multiselect-react-dropdown';
import LoadingOverlay from 'react-loading-overlay';
export default class ElementosNew extends Component {
    handleClose = () => {
        console.log('Cerrando modal y limpiando variables');
        this.props.deleteVariables(); // Primero limpiamos las variables
        this.props.onClose(); // Luego cerramos el modal
    }
    
    render() {
        const propsInt = this.props;
        const stateFather = this.props.stateFather;

        return (
            <ModalLg  isOpen={propsInt.isOpen}  onClose={propsInt.onClose} modalTitle={propsInt.modalTitleElementos}>
                <div className="modal fade" id="modal-lg" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title font-weight-bold">{propsInt.modalTitleElementos}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span onClick={this.handleClose} aria-hidden="true">×</span>
                        </button>
                    </div>                
                    <LoadingOverlay active={stateFather.loadingModalEdit} spinner text='Cargando contenido...' >
                        <form onSubmit={propsInt.handleSubmitElemento}>
                        <div className="modal-body">
                            <div>
                                <div className="form-group">
                                    <label className="col-sm-2 col-form-label">Nombre</label>
                                    <div className="col-sm-9">                                       
                                        <input type="text" value={stateFather.name} onChange={propsInt.handleChangeI} className="form-control" id="name" name="name" placeholder="Nombre elemento" />
                                        {stateFather.errorsForm.name.length > 0 && <span className='error error-class-i'>{stateFather.errorsForm.name}</span>}
                                    </div>
                                </div>                                
                                <div className="modal-footer row">
                                    <div className="col-12 d-flex justify-content-between">
                                        <button type="button" onClick={this.handleClose} className="btn btn-outline-primary" data-dismiss="modal">Cerrar</button>
                                        <button  type="submit" onClick={propsInt.handleClickBs} className="btn btn-primary">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    </LoadingOverlay>
                </div>
                </div>
                </div>
            </ModalLg>
        )
    }
}
