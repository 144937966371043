import React, { Component } from 'react';
import ModalLg from '../../ModalLg';
import LoadingOverlay from 'react-loading-overlay';

export default class SesionesShow extends Component {
    render() {
        const propsInt = this.props;
        const stateFather = this.props.stateFather;

        return (
            <ModalLg isOpen={propsInt.isOpen} onClose={propsInt.onClose} modalTitle={propsInt.modalTitle}>
                <div className="modal fade" id="modal-lg-sh" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <LoadingOverlay active={stateFather.loadingModalShow} spinner text='Cargando contenido...'>
                                <div className="modal-header">
                                    <h5 className="modal-title font-weight-bold">{propsInt.modalTitle}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span onClick={propsInt.deleteVariables} aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body d-flex">
                                    <div className="col-6">
                                        <label className="font-weight-bold">Nombre</label>
                                        <p className="font-weight-bold">{stateFather.name}</p>
                                    </div>
                                    {stateFather.showsubsecciones !== false && (
                                    <div className="col-6 border-left pl-4">
                                        <h5 className="font-weight-bold">Sub secciones dependientes</h5>
                                        <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th className="font-weight-bold border-bottom"
                                                    style={{ 
                                                        backgroundColor: 'transparent'
                                                    }}
                                                    >Nombre</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stateFather.subsecciones && stateFather.subsecciones.length > 0 ? (
                                                    stateFather.subsecciones.map((sub, index) => (
                                                        <tr key={index}>
                                                            <td className="py-2 border-bottom">{sub.nombre_subseccion}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td className="text-muted">No hay subsecciones</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    )}
                                </div>
                                <div className="modal-footer d-flex justify-content-between">
                                    <button type="button" className="btn btn-outline-primary" onClick={propsInt.deleteVariables} data-dismiss="modal">Cerrar</button>
                                    <button type="button" className="btn btn-outline-danger" onClick={() => {propsInt.deleteSesion(stateFather.selectedId, stateFather.selectedTab);propsInt.onClose();}}>Eliminar</button>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>
            </ModalLg>
        );
    }
}