import React, { Component } from 'react';
import CampanasSesiones from '../CampanasSesiones/CampanasSesiones';
import AdminServices from '../../../services/CampanasSesionesServices';
import swal from 'sweetalert';
import $ from "jquery";
import { faPencilAlt , faEye , faTrashAlt, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../styles/users.css';
import {hasPermission} from '../../../util/auth';
export default class CampanasSesionesContainer extends Component {
    state = {
        modalIsopen:false,
        modalCreateTitle:'Created Sesion',
        modalTitleSesiones:'Crear sección',
        modalTitleSubsecciones:'Crear sub sección',
        modalTitleTpCampanas:'Crear tipo de campaña',
        modalTitleVisibilidad:'Crear visibilidad',
        modalTitleCategorias:'Crear categoría',
        modalTitleElementos:'Crear elemento',
        dataActive:[],
        dataInactive: [],
        dataDeleted: [],
        listaTipos: [],
        listaTiposTpElementos: [],
        listaTiposTpCampana: [],
        listaCategoriasSubsecciones: [],
        subseccionesSeleccionadas: [],
        error: null,
        errorsForm: {
            name: '',
            number:'',
            descripcion:'',
            subsecciones: ''
        },
        fallo: false,
        headData:[{
            dataField: '',
            text: ''
        }],
        number:'',
        name:'',
        descripcion:'',
        sesionesDetailsData: {},
        elementosDetailsData: {},
        tpCampanaDetailsData: {},
        tpVisibilidadDetailsData: {},
        subseccionesDetailsData: {},
        sesionesNumeroData: {},
        dataElementos: [],
        dataTpCampanas: [],
        dataTpCampanaVisibilidad: [],
        dataSesiones: [],
        dataSubSecciones: [],
        dataCategorias: [],
        columnConfig: {
            secciones: [
                { dataField: 'id', text: 'N°', headerStyle: { width: '6%' }, sort: true },
                { dataField: 'nombre', text: 'Nombre', headerStyle: { width: 'auto' }, sort: true },
                { dataField: 'subsecciones', text: 'Sub secciones dependientes', headerStyle: { width: 'auto' }, formatter: (cell, row) => {
                        if (!cell || cell.length === 0) {
                            return <span className="text-muted">Sin subsecciones</span>;
                        }
                        return (
                            <div className="d-flex flex-wrap gap-1">
                                {cell.map((subseccion, index) => (
                                    <span 
                                        key={index} 
                                        className="badge badge-info mr-1 mb-1"
                                        style={{ 
                                            fontSize: '0.9em',
                                            padding: '5px 10px',
                                            backgroundColor: '#0096fb',
                                            color: 'white',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        {subseccion.nombre}
                                    </span>
                                ))}
                            </div>
                        );
                    },
                    sort: true
                },
                { dataField: 'actions', text: 'Acciones', formatter: this.actionsFormater.bind(this), headerStyle: { width: '150px' } }
            ],
            sub_secciones: [
                { dataField: 'id', text: 'N°', headerStyle: { width: '6%' }, sort: true },
                { dataField: 'nombre', text: 'Nombre', headerStyle: { width: 'auto' }, sort: true },
                { dataField: 'number', text: 'Número SAP', headerStyle: { width: '15%' }, sort: true },
                { dataField: 'subsecciones', text: 'Categorías dependientes', headerStyle: { width: 'auto' }, formatter: (cell, row) => {
                        if (!cell || cell.length === 0) {
                            return <span className="text-muted">Sin categorías</span>;
                        }
                        return (
                            <div className="d-flex flex-wrap gap-1">
                                {cell.map((subseccion, index) => (
                                    <span 
                                        key={index} 
                                        className="badge badge-info mr-1 mb-1"
                                        style={{ 
                                            fontSize: '0.9em',
                                            padding: '5px 10px',
                                            backgroundColor: '#0096fb',
                                            color: 'white',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        {subseccion.nombre}
                                    </span>
                                ))}
                            </div>
                        );
                    },
                    sort: true
                },
                { dataField: 'actions', text: 'Acciones', formatter: this.actionsFormater.bind(this), headerStyle: { width: '150px' } }
            ],
            visibilidad: [
                { dataField: 'id', text: 'N°', headerStyle: { width: '6%' }, sort: true },
                { dataField: 'nombre', text: 'Nombre', headerStyle: { width: 'auto' }, sort: true },
                { dataField: 'subsecciones', text: 'Tipo Campaña dependientes', headerStyle: { width: 'auto' }, formatter: (cell, row) => {
                        if (!cell || cell.length === 0) {
                            return <span className="text-muted">Sin tipo de campaña</span>;
                        }
                        return (
                            <div className="d-flex flex-wrap gap-1">
                                {cell.map((subseccion, index) => (
                                    <span 
                                        key={index} 
                                        className="badge badge-info mr-1 mb-1"
                                        style={{ 
                                            fontSize: '0.9em',
                                            padding: '5px 10px',
                                            backgroundColor: '#0096fb',
                                            color: 'white',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        {subseccion.nombre}
                                    </span>
                                ))}
                            </div>
                        );
                    },
                    sort: true
                },
                { dataField: 'actions', text: 'Acciones', formatter: this.actionsFormater.bind(this), headerStyle: { width: '150px' } }
            ],
            tipo_campana: [
                { dataField: 'id', text: 'N°', headerStyle: { width: '6%' }, sort: true },
                { dataField: 'nombre', text: 'Nombre', headerStyle: { width: 'auto' }, sort: true },
                { dataField: 'subsecciones', text: 'Elementos dependientes', headerStyle: { width: 'auto' }, formatter: (cell, row) => {
                        if (!cell || cell.length === 0) {
                            return <span className="text-muted">Sin elementos</span>;
                        }
                        return (
                            <div className="d-flex flex-wrap gap-1">
                                {cell.map((subseccion, index) => (
                                    <span 
                                        key={index} 
                                        className="badge badge-info mr-1 mb-1"
                                        style={{ 
                                            fontSize: '0.9em',
                                            padding: '5px 10px',
                                            backgroundColor: '#0096fb',
                                            color: 'white',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        {subseccion.nombre}
                                    </span>
                                ))}
                            </div>
                        );
                    },
                    sort: true
                },
                { dataField: 'actions', text: 'Acciones', formatter: this.actionsFormater.bind(this), headerStyle: { width: '150px' } }
            ],
            categorias: [
                { dataField: 'id', text: 'N°', headerStyle: { width: '6%' }, sort: true },
                { dataField: 'nombre', text: 'Nombre', headerStyle: { width: 'auto' }, sort: true },
                { dataField: 'actions', text: 'Acciones', formatter: this.actionsFormater.bind(this), headerStyle: { width: '150px' } }
            ],
            elementos: [
                { dataField: 'id', text: 'N°', headerStyle: { width: '6%' }, sort: true },
                { dataField: 'nombre', text: 'Nombre', headerStyle: { width: 'auto' }, sort: true },
                { dataField: 'actions', text: 'Acciones', formatter: this.actionsFormater.bind(this), headerStyle: { width: '150px' } }
            ]
        }
    }
    /*constructor(){
        super(); 
    }*/

    async componentDidMount(){
        await this.getElementos();    
        await this.getSesionesCampana();
        await this.getSubsecciones();
        await this.get_tp_campanas();
        await this.getTpElementos();
        await this.get_tp_campana_visibilidad();
        await this.getTpCampana();
        await this.getSubSeccionesCampana();
        await this.getCategorias();
        await this.getCategoriasSubsecciones();
    }

    getSubsecciones = async () => {
        try {
            this.setState({ loadingSubsecciones: true });
            const response = await AdminServices.getSubsecciones();
            
            if (response.data.status && response.data.data) {
                this.setState({
                    listaTipos: response.data.data.map(subseccion => ({
                        id: subseccion.id,
                        nombre: subseccion.nombre
                    })),
                    errorSubsecciones: null
                });
            } else {
                this.setState({
                    errorSubsecciones: 'Error al cargar las subsecciones',
                    listaTipos: []
                });
                swal({
                    title: "Error",
                    text: response.data.message || "No se pudieron cargar las subsecciones",
                    icon: "error",
                    button: "Ok"
                });
            }
        } catch (error) {
            this.setState({
                errorSubsecciones: 'Error al cargar las subsecciones',
                listaTipos: []
            });
            swal({
                title: "Error",
                text: "Ocurrió un error al cargar las subsecciones",
                icon: "error",
                button: "Ok"
            });
        } finally {
            this.setState({ loadingSubsecciones: false });
        }
    };

    getTpElementos = async () => {
        try {
            this.setState({ loadingSubsecciones: true });
            const response = await AdminServices.getTpElementos();
            if (response.data.status && response.data.data) {
                this.setState({
                    listaTiposTpElementos: response.data.data.map(tpElemento => ({
                        id: tpElemento.id,
                        nombre: tpElemento.name
                    })),
                    errorSubsecciones: null
                });
            } else {
                this.setState({
                    errorSubsecciones: 'Error al cargar las elementos',
                    listaTiposTpElementos: []
                });
                swal({
                    title: "Error",
                    text: response.data.message || "No se pudieron cargar los elementos",
                    icon: "error",
                    button: "Ok"
                });
            }
        } catch (error) {
            this.setState({
                errorSubsecciones: 'Error al cargar los elementos',
                listaTiposTpElementos: []
            });
            swal({
                title: "Error",
                text: "Ocurrió un error al cargar los elementos",
                icon: "error",
                button: "Ok"
            });
        } finally {
            this.setState({ loadingSubsecciones: false });
        }
    };

    getSesionesCampana = async function() {
        this.setState({ loading: true, error: null });
        try {
            const response = await AdminServices.getSesionesCampana();
            if (!response.hasOwnProperty('errorInfo')) {
                const sesiones = Array.isArray(response.data.data) ? response.data.data : [];
                this.setState({
                    loading: false,
                    dataSesiones: sesiones,
                    error: null
                });
            } else {
                this.setState({
                    loading: false,
                    dataSesiones: [],
                    error: response.error || 'Error al cargar las sesiones'
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
                dataSesiones: [],
                error: 'Error al cargar las sesiones'
            });
        }
    };
 
    getSubSeccionesCampana = async function() {
        this.setState({ loading: true, error: null });
        try {
            const response = await AdminServices.getSubSeccionesCampana();
            console.log('esto es la respuesta de las subsecciones', response);
            if (!response.hasOwnProperty('errorInfo')) {
                const subsecciones = Array.isArray(response.data.data) ? response.data.data : [];
                console.log('esto es la subsecciones', subsecciones);
                this.setState({
                    loading: false,
                    dataSubSecciones: subsecciones,
                    error: null
                });
            } else {
                this.setState({
                    loading: false,
                    dataSubSecciones: [],
                    error: response.error || 'Error al cargar las subsecciones'
                });
            }
        } catch (error) {
            console.error('Error al obtener subsecciones:', error);
            this.setState({
                loading: false,
                dataSubSecciones: [],
                error: 'Error al cargar las subsecciones'
            });
        }
    };

    trasfData = async function(){
        this.setState({
            loading:true,
        });
        const columns = [
            {
                dataField: 'id',
                sort: true,
                text: 'N°',
                headerStyle: {
                    width: '6%'
                },
            }, 
            {
                dataField: 'nombre',
                text: 'Nombre',
                headerStyle: {
                    width: '10%'
                },
                sort: true,
            },
            {
                dataField: 'subsecciones',
                text: 'Subsecciones dependientes',
                headerStyle: {
                    //width: '10%'
                    width: 'auto'
                },
                formatter: (cell, row) => {
                    if (!cell || cell.length === 0) {
                        return <span className="text-muted">Sin subsecciones</span>;
                    }
                    return (
                        <div className="d-flex flex-wrap gap-1">
                            {cell.map((subseccion, index) => (
                                <span 
                                    key={index} 
                                    className="badge badge-info mr-1 mb-1"
                                    style={{ 
                                        fontSize: '0.9em',
                                        padding: '5px 10px',
                                        backgroundColor: '#17a2b8',
                                        color: 'white',
                                        borderRadius: '4px'
                                    }}
                                >
                                    {subseccion.nombre}
                                </span>
                            ))}
                        </div>
                    );
                },
                sort: true,
            },
            {
                dataField: 'actions',
                text: 'Acciones',
                formatter: this.actionsFormater.bind(this),
                headerStyle: {
                    //width: '5%'
                    width: '150px'
                }
            },    
        ];
        
        this.setState({
            loading: false,
            headData: columns,
        });
    }

    handleChangeI = e =>{
        this.setState({
            [e.target.name] : e.target.value
        });
        const { name, value } = e.target;
        let errors = this.state.errorsForm;
        switch (name) {
            case 'name': 
                errors.name ='';
                if(value===''){
                    errors.name ='El nombre de la sección es requerido';
                }
            break;
            case 'numero': 
                errors.numero ='';
                this.getNumeroSesiones(value);
                if(this.state.sesionesNumeroData.length['id']){
                    if(!this.state.editSesionEstate){
                        errors.number ='El número de la sección ya existe';
                    }
                }                
                if(value===''){
                    errors.number ='El número de la sección es requerido';
                }
            break;
            case 'subsecciones':
                errors.subsecciones = '';
                // No validamos aquí porque la validación se hace al agregar
                break;
            default:
              break;
          }
        this.setState({errors, [name]: value});
    }

    async validateFormElemenoSubmit(){
        let errors = this.state.errorsForm;
        errors.name =''; 
        
        if(this.state.name===''){
            errors.name ='El nombre del elemento es requerido!';
        }
        this.setState({errorsForm:errors});
    }

    async validateFormPreSubmit(){
        let errors = {
            name: '',
            number: '',
            subsecciones: ''
        };
        console.log('esto es le validateFormPreSubmit', this.state)
        if(this.state.name===''){
            errors.name ='El nombre de la sección es requerido!';
        }

        if (this.state.subseccionesSeleccionadas.length === 0) {
            errors.subsecciones = 'Debe agregar al menos una subsección!';
        }

        this.setState({errorsForm:errors});
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
          (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
    deleteVariables = e =>{
        console.log('vino a deleteVariables');
        this.setState({
            modalIsopen:false,
            modalCreateTitle:'Created Sala',
            modalTitleSesiones:'Crear sección',
            modalTitleSubsecciones:'Crear subsección',
            modalTitleTpCampanas:'Crear tipo de campaña',
            modalTitleVisibilidad:'Crear visibilidad',
            modalTitleCategorias:'Crear categoría',
            modalTitleElementos:'Crear elemento',
            currentModal: '',
            number:'',
            subseccionesSeleccionadas:[],
            subseccionNombre:'',
            name:'',
            descripcion:'',
            sesionesDetailsData: {},
            sesionesNumeroData:{},
            editSesionEstate:false,
            errorsForm: {
                name: '',
                number:'',
                descripcion:'',
                subsecciones:'' 
            },
            
        });
    }
    handleCloseModal = e =>{
        console.log('vino a handleCloseModal');
        this.setState({modalIsopen:false, currentModal: ''})
    }
    handleClickBs = e =>{
        console.log('boton precionDO');
    }
    getNumeroSesiones = async function(NumeroSesion){
        this.setState({loading:true , error: null})
        await AdminServices.getNumeroSesiones(NumeroSesion).then((data) => {
            if(!data.hasOwnProperty('errorInfo')){
                this.setState({loading:false, sesionesNumeroData: data.data});
            }else{
                this.setState({ loading:false , error : data.error})
            }
        })
    }

    handleSubmitElemento = async e =>{
        e.preventDefault();
        await this.validateFormElemenoSubmit();
        if(this.validateForm(this.state.errorsForm)) {
            console.log('esto es le handleSubmitElemento', this.state.editSesionEstate)
            if(!this.state.editSesionEstate){
                swal({
                    title: "¿Está seguro de guardar el elemento?",
                    text: "¡El elemento se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.createElemento()
                    }
                });
            }else{
                swal({
                    title: "¿Está seguro que desea Actualizar el Elemento?",
                    text: "El elemento se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.updateElementos()
                    }
                });
            }
        }else{
            return
        }       
    }

    handleSubmitBs = async e =>{
        e.preventDefault();
        await this.validateFormPreSubmit();
        if(this.validateForm(this.state.errorsForm)) {
            if(!this.state.editSesionEstate){
                console.log('esto es le createSesion')
                swal({
                    title: "¿Está seguro de guardar la sección?",
                    text: "¡La sección se habilitara para todas las salas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.createSesion()
                        $("body").removeClass("modal-open");
                    }
                });
            }else{
                swal({
                    title: "¿Está seguro que desea actualizar la sección?",
                    text: "¡La sección se habilitara para todas las salas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.updateSesion()
                        $("body").removeClass("modal-open");
                    }
                });
            }
        }else{
            return
        }       
    }
    sesionesFormater (cell){
        //let namesCad = cell.map(cad => cad.nombre)
        let namesCad = cell.nombre
        return namesCad;
    }

    /*MODULO DE TIPO DE CAMPAÑAS */
    get_tp_campanas = async function() {
        this.setState({ loading: true, error: null });
        try {
            const response = await AdminServices.get_tpCampanas();
            if (!response.hasOwnProperty('errorInfo')) {
                const tp_campanas = Array.isArray(response.data.data) ? response.data.data : [];
                this.setState({
                    loading: false,
                    dataTpCampanas: tp_campanas,
                    error: null
                });
            } else {
                this.setState({
                    loading: false,
                    dataTpCampanas: [],
                    error: response.error || 'Error al cargar los tipo de campañas'
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
                dataTpCampanas: [],
                error: 'Error al cargar los tipo de campañas'
            });
        }
    };

    showTpCampana = async (sala) =>{
        this.setState({modalIsopen:true , modalTitle:'Detalle tipo campaña', loadingModalShow:true});
            await this.getDetailsTpCampana(sala);
            await this.setVarshowTpCampana();
        this.setState({loadingModalShow:false});
    }

    getDetailsTpCampana = async function(TpCampanaId){
        this.setState({loading:false , error: null})
        await AdminServices.getTpCampanaDetail(TpCampanaId).then((data) => {
            if(!data.hasOwnProperty('errorInfo')){
                this.setState({loading:false, tpCampanaDetailsData: data.data});
            }else{
                this.setState({ loading:false , error : data.error})
            }
        })
    }

    setVarshowTpCampana = async () =>{
        this.setState(
        {
            modalTitle: this.state.tpCampanaDetailsData.name,
            editSesionEstate:false,
            name:this.state.tpCampanaDetailsData.name,
            showsubsecciones: true,
            subsecciones:this.state.tpCampanaDetailsData.elementos,    
            selectedId: this.state.tpCampanaDetailsData.id,
            selectedTab: 'tpCampana'
        })
        this.setState({modalIsopen:true})
    }

    statusTpCampana = async (sesion) =>{
        swal({
            title: "¿Está seguro que desea desactivar el tipo de campaña?",
            text: "¡El tipo de campaña no podrá ser usado en el sistema!",
            icon: "warning",
            buttons: true,
            //dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try{
                    let statusTpCampana = ({
                        id:sesion
                    });
                    this.setState({
                        formAlldate : statusTpCampana
                    })
                    this.setState({loading:true , error: null})
                    AdminServices.statusTpCampana(statusTpCampana).then((data) => {
                        if(!data.hasOwnProperty('errorInfo')){
                            swal({
                                title: `Tipo de campaña desactivada con éxito!`,
                                //text: "!",
                                icon: "success",
                                button: "Ok!",
                            });
                            this.get_tp_campanas();
                        }else{
                            swal({
                                title: `Error ${data.errorInfo.toString()} `,
                                text: "!",
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    })
                    this.setState({loading:false , error: null})
                } catch(error){
                    this.setState({loading:false , error: error})
                }
            }
        });
    }

    newOrEditTpCampana = async (sesion) =>{
        if(sesion === 0){
            this.setState({modalIsopen:true});
        }else{
            this.setState({modalIsopen:true , modalTitle:'Editar tipo de campaña' , loadingModalEdit:true, currentModal: 'tipo-campana'});
                await this.getDetailsTpCampana(sesion);
                await this.setVarEditTpCampana();
            this.setState({loadingModalEdit:false});
        }
    }

    setVarEditTpCampana = async () =>{
        this.setState(
            {
                modalTitle:this.state.tpCampanaDetailsData.name,
                editSesionEstate:true,
                name:this.state.tpCampanaDetailsData.name,
                subseccionesSeleccionadas:this.state.tpCampanaDetailsData.elementos,        
            })
        this.setState({modalIsopen:true})
    }

    handleSubmitTpCampana = async e =>{
        e.preventDefault();
        await this.validateFormPreSubmit();
        if(this.validateForm(this.state.errorsForm)) {
            if(!this.state.editSesionEstate){
                swal({
                    title: "¿Está seguro de guardar el tipo de campaña?",
                    text: "¡El tipo de campaña se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.createTpCampana()
                        $("body").removeClass("modal-open");
                    }
                });
            }else{
                swal({
                    title: "¿Está seguro que desea actualizar el tipo de campaña?",
                    text: "¡El tipo de campaña se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.updateTpCampana()
                        $("body").removeClass("modal-open");
                    }
                });
            }
        }else{
            return
        }       
    }

    createTpCampana = async function(){     

        let newTpCampana = ({
            nombre:this.state.name,
            subsecciones: this.state.subseccionesSeleccionadas
        });
        this.setState({
            formAlldate : newTpCampana
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.storeTpCampana(newTpCampana).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `Tipo de campaña registrada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();
                    this.getTpCampana();
                    this.get_tp_campanas();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.get_tp_campanas();
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    updateTpCampana = async function(){ 
        this.setState({loading:true})
        let updateTpCampana = ({            
            nombre:this.state.name,
            subsecciones: this.state.subseccionesSeleccionadas
        });
        this.setState({
            formAlldate : updateTpCampana
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.updateTpCampana(updateTpCampana, this.state.tpCampanaDetailsData.id).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `Tipo de campaña actualizada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();;
                    this.get_tp_campanas();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.get_tp_campanas();
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }
    /*FIN MODULO DE TIPO DE CAMPAÑAS */

    /*MODULO DE ELEMENTOS */
    getElementos = async function() {
        this.setState({ loading: true, error: null });
        try {
            const response = await AdminServices.getElementos();
            if (!response.hasOwnProperty('errorInfo')) {
                const elementos = Array.isArray(response.data.data) ? response.data.data : [];
                this.setState({
                    loading: false,
                    dataElementos: elementos,
                    error: null
                });
            } else {
                this.setState({
                    loading: false,
                    dataElementos: [],
                    error: response.error || 'Error al cargar los elementos'
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
                dataElementos: [],
                error: 'Error al cargar los elementos'
            });
        }
    };

    statusElemento = async (elemento) =>{
        swal({
            title: "¿Está seguro que desea desactivar el elemento?",
            text: "¡El elemento no podrá ser usado en el sistema!",
            icon: "warning",
            buttons: true,
            //dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try{
                    let statusElemento = ({
                        id:elemento
                    });
    
                    this.setState({
                        formAlldate : statusElemento
                    })
                    this.setState({loading:true , error: null})
                    AdminServices.statusElemento(statusElemento).then((data) => {
                        if(!data.hasOwnProperty('errorInfo')){
                            swal({
                                title: `¡Elemento desactivado con éxito!`,
                                //text: "!",
                                icon: "success",
                                button: "Ok!",
                            });
                            this.getElementos();
                        }else{
                            swal({
                                title: `Error ${data.errorInfo.toString()} `,
                                text: "!",
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    })
                    this.setState({loading:false , error: null})
                } catch(error){
                    this.setState({loading:false , error: error})
                }
            }
        });
    }

    createElemento = async function(){        
        let newElemento = ({
            nombre:this.state.name,
        });
        this.setState({
            formAlldate : newElemento
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.storeElemento(newElemento).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Elemento registrado con éxito!`,
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();
                    this.getTpElementos();
                    this.getElementos();
                    $("body").removeClass("modal-open");
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();
            await this.getElementos();
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    showElemento = async (sala) =>{
        this.setState({modalIsopen:true , modalTitle:'Detalle sección', loadingModalShow:true});
            await this.getDetailsElementos(sala);
            await this.setVarShowElemento();
        this.setState({loadingModalShow:false});
    }

    getDetailsElementos = async function(ElementosId){
        this.setState({loading:false , error: null})
        await AdminServices.getElementosDetail(ElementosId).then((data) => {
            if(!data.hasOwnProperty('errorInfo')){
                this.setState({loading:false, elementosDetailsData: data.data});
            }else{
                this.setState({ loading:false , error : data.error})
            }
        })
    }

    setVarShowElemento = async () =>{
        this.setState(
        {
            modalTitle: this.state.elementosDetailsData.name,
            editSesionEstate:false,
            name:this.state.elementosDetailsData.name,
            showsubsecciones: false,  
            selectedId: this.state.elementosDetailsData.id,
            selectedTab: 'elementos'
        })
        this.setState({modalIsopen:true})
    }

    newOrEditElementos = async (sesion) =>{
        if(sesion === 0){
            this.setState({modalIsopen:true});
        }else{
            this.setState({modalIsopen:true , modalTitle:'Editar elemento' , loadingModalEdit:true, currentModal: 'elemento'});
                await this.getDetailsElementos(sesion);
                await this.setVarEditElementos();
            this.setState({loadingModalEdit:false});
        }
    }

    setVarEditElementos = async () =>{
        this.setState(
            {
                modalTitle:'Editar elemento',
                editSesionEstate:true,
                name:this.state.elementosDetailsData.name,        
            })
        this.setState({modalIsopen:true})
    }

    updateElementos = async function(){ 
        this.setState({loading:true})
        let UpdateElementos = ({            
            nombre:this.state.name,
        });
        this.setState({
            formAlldate : UpdateElementos
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.updateElementos(UpdateElementos, this.state.elementosDetailsData.id).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Sección actualizada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();
                    this.getElementos();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();
            await this.getElementos();   
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    /*FIN MODULO DE ELEMENTOS */
    deleteSesion = async function(id, tab){
        const mensajes = {
            'sesiones': {
                titulo: "¿Está seguro que desea eliminar la sección?",
                texto: "La sección no podrá ser usada en el sistema!",
                exito: "Sección eliminada con éxito"
            },
            'subsecciones': {
                titulo: "¿Está seguro que desea eliminar la subsección?",
                texto: "La subsección no podrá ser usada en el sistema!",
                exito: "Subsección eliminada con éxito"
            },
            'categorias': {
                titulo: "¿Está seguro que desea eliminar la categoría?",
                texto: "La categoría no podrá ser usada en el sistema!",
                exito: "Categoría eliminada con éxito"
            },
            'tpVisibilidad': {
                titulo: "¿Está seguro que desea eliminar el tipo de visibilidad?",
                texto: "El tipo de visibilidad no podrá ser usado en el sistema!",
                exito: "Tipo de Visibilidad eliminado con éxito"
            },
            
            'tpCampana': {
                titulo: "¿Está seguro que desea eliminar el tipo de campaña?",
                texto: "El tipo de campaña no podrá ser usado en el sistema!",
                exito: "Tipo de Campaña eliminado con éxito"
            },
            'elementos': {
                titulo: "¿Está seguro que desea eliminar el elemento?",
                texto: "El elemento no podrá ser usado en el sistema!",
                exito: "Elemento eliminado con éxito"
            }

        };
    
        const ejecutarServicio = async (tipo, datos) => {
            switch(tipo) {
                case 'sesiones':
                    return await AdminServices.deleteSesionCampana(datos);
                case 'subsecciones':
                    return await AdminServices.deleteSubseccion(datos);
                case 'categorias':
                    return await AdminServices.deleteCategorias(datos);
                case 'tpVisibilidad':
                    return await AdminServices.deleteTpVisibilidad(datos);
                case 'tpCampana':
                    return await AdminServices.deleteTpCampana(datos);
                case 'elementos':
                    return await AdminServices.deleteElementos(datos);
                default:
                    throw new Error('Tipo de servicio no válido');
            }
        };
    
        try {
            const willDelete = await swal({
                title: mensajes[tab].titulo,
                text: mensajes[tab].texto,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });
    
            if (willDelete) {
                //this.setState({loading:true , error: null})
                const data = await ejecutarServicio(tab, { id });
    
                if (data?.data === 'OK') {
                    await swal({
                        title: mensajes[tab].exito,
                        icon: "success",
                        button: "Ok!",
                    });
    
                    this.deleteVariables();
                    // Limpiar el backdrop del modal
                    document.querySelector('.modal-backdrop')?.classList.remove('show');
                    setTimeout(() => {
                        document.querySelector('.modal-backdrop')?.remove();
                    }, 150);
                    window.location.reload();
    
                } else {
                    await swal({
                        title: "Error al eliminar el registro",
                        icon: "error",
                        button: "Ok!",
                    });
                    
                }
            }
        } catch (error) {
            console.error('Error:', error);
            
            await swal({
                title: "Error",
                text: "Ocurrió un error al eliminar",
                icon: "error",
                button: "Ok!",
            });
        }
        
    }

    /*MODULO DE SESIONES */
    showSesion = async (sala) =>{
        this.setState({modalIsopen:true , modalTitle:'Detalle sección', loadingModalShow:true});
            await this.getDetailsSesiones(sala);
            await this.setVarShowSesion();
        this.setState({loadingModalShow:false});
    }

    setVarShowSesion = async () =>{
        this.setState(
        {
            modalTitle:this.state.sesionesDetailsData.nombre,
            editSesionEstate:false,
            name:this.state.sesionesDetailsData.nombre,
            showsubsecciones: true,
            subsecciones:this.state.sesionesDetailsData.subsecciones,
            selectedId: this.state.sesionesDetailsData.id,
            selectedTab: 'sesiones'
            
        })
        this.setState({modalIsopen:true})
    }

    newOrEditSesiones = async (sesion) =>{
        if(sesion === 0){
            this.setState({modalIsopen:true});
        }else{
            this.setState({modalIsopen:true , modalTitle:'Editar sección' , loadingModalEdit:true, currentModal: 'seccion'});
                await this.getDetailsSesiones(sesion);
                await this.setVarEditSesiones();
            this.setState({loadingModalEdit:false});
        }
    }

    createSesion = async function(){     
        let newSesion = ({
            nombre:this.state.name,
            subsecciones: this.state.subseccionesSeleccionadas
        });
        this.setState({
            formAlldate : newSesion
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.storeSesionesCampana(newSesion).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Sección registrada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();;
                    this.getSesionesCampana();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.getSesionesCampana();    
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    getDetailsSesiones = async function(SesionesId){
        this.setState({loading:false , error: null})
        await AdminServices.getSesionesCampanaDetail(SesionesId).then((data) => {
            console.log('esto es le getSesionesCampanaDetail' ,data)
            if(!data.hasOwnProperty('errorInfo')){
                this.setState({loading:false, sesionesDetailsData: data.data});
            }else{
                this.setState({ loading:false , error : data.error})
            }
        })
    }
    
    setVarEditSesiones = async () =>{
        this.setState(
            {
                modalTitle:this.state.sesionesDetailsData.nombre,
                editSesionEstate:true,
                name:this.state.sesionesDetailsData.nombre,
                subseccionesSeleccionadas:this.state.sesionesDetailsData.subsecciones,        
            })
        this.setState({modalIsopen:true})
    }

    updateSesion = async function(){ 
        this.setState({loading:true})
        let UpdateSesion = ({            
            nombre:this.state.name,
            subsecciones: this.state.subseccionesSeleccionadas
        });
        this.setState({
            formAlldate : UpdateSesion
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.updateSesionCampana(UpdateSesion, this.state.sesionesDetailsData.id).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Sección actualizada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();;
                    this.getSesionesCampana();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.getSesionesCampana();  
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }
    
    statusSesion = async (sesion) =>{
        swal({
            title: "¿Está seguro que desea desactivar la sección?",
            text: "¡La sección no podrá ser usada en el sistema!",
            icon: "warning",
            buttons: true,
            //dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try{
                    let statusSesion = ({
                        id:sesion
                    });
                    console.log(statusSesion)
                    this.setState({
                        formAlldate : statusSesion
                    })
                    this.setState({loading:true , error: null})
                    AdminServices.statusSesionCampana(statusSesion).then((data) => {
                        console.log('esto es le retornoooooooooooooooooooo' ,data)
                        if(!data.hasOwnProperty('errorInfo')){
                            swal({
                                title: `¡Sección desactivada con éxito!`,
                                //text: "!",
                                icon: "success",
                                button: "Ok!",
                            });
                            this.getSesionesCampana();
                            this.getSesionesInactive();
                            this.getSesionesDelete();  
                        }else{
                            swal({
                                title: `Error ${data.errorInfo.toString()} `,
                                text: "!",
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    })
                    this.setState({loading:false , error: null})
                } catch(error){
                    this.setState({loading:false , error: error})
                }
            }
        });
    }
    /*FIN MODULO DE SESIONES */

    onSelectTipos = (selectedList, selectedItem) =>{
        this.setState({
            subseccionesSeleccionadas : selectedList
        })
    }

    onRemoveTipos = (selectedList, removedItem) => {
        this.setState({
            listaMarcasSeleccionadas : []
        })
    }

    /*MODULO DE VISIBILIDAD DE CAMPAÑAS */
    get_tp_campana_visibilidad = async function() {
        this.setState({ loading: true, error: null });
        try {
            const response = await AdminServices.get_tpCampanaVisibilidad();
            if (!response.hasOwnProperty('errorInfo')) {
                const tp_campana_visibilidad = Array.isArray(response.data.data) ? response.data.data : [];
                this.setState({
                    loading: false,
                    dataTpCampanaVisibilidad: tp_campana_visibilidad,
                    error: null
                });
            } else {
                this.setState({
                    loading: false,
                    dataTpCampanaVisibilidad: [],
                    error: response.error || 'Error al cargar las visibilidades de las campañas'
                });
            }
        } catch (error) {
            console.error('Error al obtener tipo de campañas:', error);
            this.setState({
                loading: false,
                dataTpCampanaVisibilidad: [],
                error: 'Error al cargar las visibilidades de las campañas'
            });
        }
    };
    
    statusVisibilidad = async (sesion) =>{
        swal({
            title: "¿Está seguro que desea desactivar la visibilidad de la campaña?",
            text: "¡La visibilidad de la campaña no podrá ser usada en el sistema!",
            icon: "warning",
            buttons: true,
            //dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try{
                    let statusVisibilidad = ({
                        id:sesion
                    });
                    this.setState({
                        formAlldate : statusVisibilidad
                    })
                    this.setState({loading:true , error: null})
                    AdminServices.statusTpCampanaVisibilidad(statusVisibilidad).then((data) => {
                        if(!data.hasOwnProperty('errorInfo')){
                            swal({
                                title: `Visibilidad de campaña Desactivada con éxito!`,
                                //text: "!",
                                icon: "success",
                                button: "Ok!",
                            });
                            this.get_tp_campana_visibilidad();
                        }else{
                            swal({
                                title: `Error ${data.errorInfo.toString()} `,
                                text: "!",
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    })
                    this.setState({loading:false , error: null})
                } catch(error){
                    this.setState({loading:false , error: error})
                }
            }
        });
    }

    showVisibilidad = async (sala) =>{
        this.setState({modalIsopen:true , modalTitle:'Detalle visibilidad campaña', loadingModalShow:true});
            await this.getDetailsVisibilidad(sala);
            await this.setVarshowVisibilidad();
        this.setState({loadingModalShow:false});
    }

    getDetailsVisibilidad = async function(TpVisibilidadId){
        this.setState({loading:false , error: null})
        await AdminServices.getTpCampanaVisibilidadDetail(TpVisibilidadId).then((data) => {
            if(!data.hasOwnProperty('errorInfo')){
                this.setState({loading:false, tpVisibilidadDetailsData: data.data});
            }else{
                this.setState({ loading:false , error : data.error})
            }
        })
    }

    setVarshowVisibilidad = async () =>{
        this.setState(
        {
            modalTitle: this.state.tpVisibilidadDetailsData.name,
            editSesionEstate:false,
            name:this.state.tpVisibilidadDetailsData.name,
            showsubsecciones: true,
            subsecciones:this.state.tpVisibilidadDetailsData.tp_campanas,    
            selectedId: this.state.tpVisibilidadDetailsData.id,
            selectedTab: 'tpVisibilidad'
        })
        this.setState({modalIsopen:true})
    }

    newOrEditVisibilidad = async (sesion) =>{
        if(sesion === 0){
            this.setState({modalIsopen:true});
        }else{
            this.setState({modalIsopen:true , modalTitle:'Editar visibilidad campaña' , loadingModalEdit:true, currentModal: 'visibilidad'});
                await this.getDetailsVisibilidad(sesion);
                await this.setVarEditVisibilidad();
            this.setState({loadingModalEdit:false});
        }
    }

    setVarEditVisibilidad = async () =>{
        this.setState(
            {
                modalTitle:this.state.tpVisibilidadDetailsData.name,
                editSesionEstate:true,
                name:this.state.tpVisibilidadDetailsData.name,
                subseccionesSeleccionadas:this.state.tpVisibilidadDetailsData.tp_campanas,        
            })
        this.setState({modalIsopen:true})
    }

    getTpCampana = async () => {
        try {
            this.setState({ loadingSubsecciones: true });
            const response = await AdminServices.getTpCampana();
            if (response.data.status && response.data.data) {
                this.setState({
                    listaTiposTpCampana: response.data.data.map(tpCampana => ({
                        id: tpCampana.id,
                        nombre: tpCampana.name
                    })),
                    errorSubsecciones: null
                });
            } else {
                this.setState({
                    errorSubsecciones: 'Error al cargar las campañas',
                    listaTiposTpCampana: []
                });
                swal({
                    title: "Error",
                    text: response.data.message || "No se pudieron cargar las campañas",
                    icon: "error",
                    button: "Ok"
                });
            }
        } catch (error) {
            console.error('Error al obtener las campañas:', error);
            this.setState({
                errorSubsecciones: 'Error al cargar las campañas',
                listaTiposTpCampana: []
            });
            swal({
                title: "Error",
                text: "Ocurrió un error al cargar las campañas",
                icon: "error",
                button: "Ok"
            });
        } finally {
            this.setState({ loadingSubsecciones: false });
        }
    };

    handleSubmitVisibilidad = async e =>{
        e.preventDefault();
        await this.validateFormPreSubmit();
        if(this.validateForm(this.state.errorsForm)) {
            if(!this.state.editSesionEstate){
                swal({
                    title: "¿Está seguro de guardar la visibilidad de la campaña?",
                    text: "¡La visibilidad de la campaña se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.createTpVisibilidadCampana()
                        $("body").removeClass("modal-open");
                    }
                });
            }else{
                swal({
                    title: "¿Está seguro que desea actualizar la visibilidad de la campaña?",
                    text: "¡La visibilidad de la campaña se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.updateTpVisibilidadCampana()
                        $("body").removeClass("modal-open");
                    }
                });
            }
        }else{
            return
        }       
    }

    createTpVisibilidadCampana = async function(){     
        let newTpVisibilidadCampana = ({
            nombre:this.state.name,
            subsecciones: this.state.subseccionesSeleccionadas
        });
        this.setState({
            formAlldate : newTpVisibilidadCampana
        })
        console.log('esto es le newSesion' ,newTpVisibilidadCampana)
        try{
            this.setState({loading:true , error: null})
            AdminServices.storeTpVisibilidadCampana(newTpVisibilidadCampana).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Visibilidad de campaña registrada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();;
                    this.get_tp_campana_visibilidad();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.get_tp_campana_visibilidad();
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    updateTpVisibilidadCampana = async function(){ 
        this.setState({loading:true})
        let updateTpVisibilidadCampana = ({            
            nombre:this.state.name,
            subsecciones: this.state.subseccionesSeleccionadas
        });
        this.setState({
            formAlldate : updateTpVisibilidadCampana
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.updateTpVisibilidadCampana(updateTpVisibilidadCampana, this.state.tpVisibilidadDetailsData.id).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Visibilidad de campaña actualizada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();;
                    this.get_tp_campana_visibilidad();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.get_tp_campana_visibilidad();
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }
    /*FIN MODULO DE VISIBILIDAD DE CAMPAÑAS */

    /*MODULO DE SUBSECCIONES */
    showSubsecciones = async (sala) =>{
        this.setState({modalIsopen:true , modalTitle:'Detalle subsecciones', loadingModalShow:true});
            await this.getDetailsSubsecciones(sala);
            await this.setVarshowSubsecciones();
        this.setState({loadingModalShow:false});
    }

    getDetailsSubsecciones = async function(TpVisibilidadId){
        this.setState({loading:false , error: null})
        await AdminServices.getSubSeccionesDetail(TpVisibilidadId).then((data) => {
            if(!data.hasOwnProperty('errorInfo')){
                this.setState({loading:false, subseccionesDetailsData: data.data});
            }else{
                this.setState({ loading:false , error : data.error})
            }
        })
    }

    setVarshowSubsecciones = async () =>{
        this.setState(
        {
            modalTitle: this.state.subseccionesDetailsData.nombre,
            editSesionEstate:false,
            name:this.state.subseccionesDetailsData.nombre,
            number:this.state.subseccionesDetailsData.numero,
            showsubsecciones: true,
            subsecciones:this.state.subseccionesDetailsData.sub_secciones,  
            selectedId: this.state.subseccionesDetailsData.id,  
            selectedTab: 'subsecciones'
        })
        this.setState({modalIsopen:true})
    }

    newOrEditSubsecciones = async (sesion) =>{
        if(sesion === 0){
            this.setState({modalIsopen:true});
        }else{
            this.setState({modalIsopen:true , modalTitle:'Editar subsección' , loadingModalEdit:true, currentModal: 'subseccion'});
                await this.getDetailsSubsecciones(sesion);
                await this.setVarEditSubsecciones();
            this.setState({loadingModalEdit:false});
        }
    }

    setVarEditSubsecciones = async () =>{
        console.log('esto es le setVarEditSubsecciones', this.state.subseccionesDetailsData)
        this.setState(
            {
                modalTitleSubsecciones:this.state.subseccionesDetailsData.nombre,
                editSesionEstate:true,
                name:this.state.subseccionesDetailsData.nombre,
                number:this.state.subseccionesDetailsData.cdg_int,
                subseccionesSeleccionadas:this.state.subseccionesDetailsData.sub_secciones,        
            })
        this.setState({modalIsopen:true})
    }

    handleSubmitSubsecciones = async e =>{
        e.preventDefault();
        await this.validateFormPreSubmit();
        if(this.validateForm(this.state.errorsForm)) {
            if(!this.state.editSesionEstate){
                swal({
                    title: "¿Está seguro de guardar la subsección?",
                    text: "¡La subsección se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.createSubsecciones()
                        $("body").removeClass("modal-open");
                    }
                });
            }else{
                swal({
                    title: "¿Está seguro que desea actualizar la subsección?",
                    text: "¡La subsección se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.updateSubsecciones()
                        $("body").removeClass("modal-open");
                    }
                });
            }
        }else{
            return
        }       
    }

    createSubsecciones = async function(){     
        let newSubsecciones = ({
            nombre:this.state.name,
            numero:this.state.number,
            subsecciones: this.state.subseccionesSeleccionadas
        });
        this.setState({
            formAlldate : newSubsecciones
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.storeSubsecciones(newSubsecciones).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `Subsección registrada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();
                    this.getSubsecciones();
                    this.getSubSeccionesCampana();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.getSubSeccionesCampana();
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    updateSubsecciones = async function(){ 
        this.setState({loading:true})
        let updateSubsecciones = ({            
            nombre:this.state.name,
            numero:this.state.number,
            subsecciones: this.state.subseccionesSeleccionadas
        });
        this.setState({
            formAlldate : updateSubsecciones
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.updateSubsecciones(updateSubsecciones, this.state.subseccionesDetailsData.id).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Subsección actualizada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();;
                    this.getSubSeccionesCampana();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.getSubSeccionesCampana(); 
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    statusSubsecciones = async (sesion) =>{
        swal({
            title: "¿Está seguro que desea desactivar la subsección?",
            text: "¡La subsección no podrá ser usada en el sistema!",
            icon: "warning",
            buttons: true,
            //dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try{
                    let statusSubsecciones = ({
                        id:sesion
                    });
                    this.setState({
                        formAlldate : statusSubsecciones
                    })
                    this.setState({loading:true , error: null})
                    AdminServices.statusSubsecciones(statusSubsecciones).then((data) => {
                        if(!data.hasOwnProperty('errorInfo')){
                            swal({
                                title: `¡Subsección desactivada con éxito!`,
                                //text: "!",
                                icon: "success",
                                button: "Ok!",
                            });
                            this.getSubSeccionesCampana();
                        }else{
                            swal({
                                title: `Error ${data.errorInfo.toString()} `,
                                text: "!",
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    })
                    this.setState({loading:false , error: null})
                } catch(error){
                    this.setState({loading:false , error: error})
                }
            }
        });
    }

    getCategoriasSubsecciones = async () => {
        try {
            this.setState({ loadingSubsecciones: true });
            const response = await AdminServices.getCategoriasSubsecciones();
            
            if (response.data.status && response.data.data) {
                this.setState({
                    listaCategoriasSubsecciones: response.data.data.map(categoria => ({
                        id: categoria.id,
                        nombre: categoria.nombre
                    })),
                    errorSubsecciones: null
                });
            } else {
                this.setState({
                    errorCategoriasSubsecciones: 'Error al cargar las categorias',
                    listaCategoriasSubsecciones: []
                });
                swal({
                    title: "Error",
                    text: response.data.message || "No se pudieron cargar las categorias",
                    icon: "error",
                    button: "Ok"
                });
            }
        } catch (error) {
            console.error('Error al obtener categorias:', error);
            this.setState({
                errorSubsecciones: 'Error al cargar las categorias',
                listaCategoriasSubsecciones: []
            });
            swal({
                title: "Error",
                text: "Ocurrió un error al cargar las categorias",
                icon: "error",
                button: "Ok"
            });
        } finally {
            this.setState({ loadingSubsecciones: false });
        }
    };
    /*FIN MODULO DE SUBSECCIONES */

     /*MODULO DE CATEGORIAS */
    getCategorias = async function() {
        this.setState({ loading: true, error: null });
        try {
            const response = await AdminServices.getCategorias();
            console.log('esto es la respuesta de las categorias', response);
            if (!response.hasOwnProperty('errorInfo')) {
                const categorias = Array.isArray(response.data.data) ? response.data.data : [];
                console.log('esto es la categorias', categorias);
                this.setState({
                    loading: false,
                    dataCategorias: categorias,
                    error: null
                });
            } else {
                this.setState({
                    loading: false,
                    dataCategorias: [],
                    error: response.error || 'Error al cargar las categorias'
                });
            }
        } catch (error) {
            console.error('Error al obtener categorias:', error);
            this.setState({
                loading: false,
                dataCategorias: [],
                error: 'Error al cargar las categorias'
            });
        }
    };

    handleSubmitCategorias = async e =>{
        e.preventDefault();
        await this.validateFormElemenoSubmit();
        if(this.validateForm(this.state.errorsForm)) {
            if(!this.state.editSesionEstate){
                swal({
                    title: "¿Está seguro de guardar la categoría?",
                    text: "¡La categoría se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: flase,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.createCategorias()
                        $("body").removeClass("modal-open");
                    }
                });
            }else{
                swal({
                    title: "¿Está seguro que desea actualizar la categoría?",
                    text: "¡La categoría se habilitara para todas las campañas!",
                    icon: "warning",
                    buttons: true,
                    //dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.updateCategorias()
                        $("body").removeClass("modal-open");
                    }
                });
            }
        }else{
            return
        }       
    }

    createCategorias = async function(){        
        let newCategorias = ({
            nombre:this.state.name,
        });
        this.setState({
            formAlldate : newCategorias
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.storeCategorias(newCategorias).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Categoría registrada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();
                    this.getCategoriasSubsecciones();
                    this.getCategorias();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.getCategorias(); 
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    updateCategorias = async function(){ 
        this.setState({loading:true})
        let UpdateCategorias = ({            
            nombre:this.state.name,
        });
        this.setState({
            formAlldate : UpdateCategorias
        })
        try{
            this.setState({loading:true , error: null})
            AdminServices.UpdateCategorias(UpdateCategorias, this.state.categoriasDetailsData.id).then((data) => {
                if(!data.hasOwnProperty('errorInfo')){
                    swal({
                        title: `¡Categoría actualizada con éxito!`,
                        //text: "!",
                        icon: "success",
                        button: "Ok!",
                    });
                    this.trasfData();;
                    this.getCategorias();
                }else{
                    swal({
                        title: `Error ${data.errorInfo.toString()} `,
                        text: "!",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            $('.modal-backdrop').remove();
            this.deleteVariables();
            await this.trasfData();;
            await this.getCategorias();    
            this.setState({loading:false , error: null})
        } catch(error){
            this.setState({loading:false , error: error})
        }
    }

    showCategorias = async (sala) =>{
        this.setState({modalIsopen:true , modalTitle:'Detalle categoría', loadingModalShow:true});
            await this.getDetailsCategorias(sala);
            await this.setVarshowCategorias();
        this.setState({loadingModalShow:false});
    }

    getDetailsCategorias = async function(CategoriasId){
        this.setState({loading:false , error: null})
        await AdminServices.getCategoriasDetail(CategoriasId).then((data) => {
            if(!data.hasOwnProperty('errorInfo')){
                this.setState({loading:false, categoriasDetailsData: data.data});
            }else{
                this.setState({ loading:false , error : data.error})
            }
        })
    }

    setVarshowCategorias = async () =>{
        this.setState(
        {
            modalTitle: this.state.categoriasDetailsData.nombre,
            editSesionEstate:false,
            showsubsecciones: false,
            name: this.state.categoriasDetailsData.nombre,  
            selectedId: this.state.categoriasDetailsData.id,
            selectedTab: 'categorias'
        })
        this.setState({modalIsopen:true})
    }

    statusCategorias = async (sesion) =>{
        swal({
            title: "¿Está seguro que desea desactivar la categoría?",
            text: "¡La categoría no podrá ser usada en el sistema!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try{
                    let statusCategorias = ({
                        id:sesion
                    });
                    this.setState({
                        formAlldate : statusCategorias
                    })
                    this.setState({loading:true , error: null})
                    AdminServices.statusCategorias(statusCategorias).then((data) => {
                        if(!data.hasOwnProperty('errorInfo')){
                            swal({
                                title: `¡Categoría desactivada con éxito!`,
                                //text: "!",
                                icon: "success",
                                button: "Ok!",
                            });
                            this.getCategorias();
                        }else{
                            swal({
                                title: `Error ${data.errorInfo.toString()} `,
                                text: "!",
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    })
                    this.setState({loading:false , error: null})
                } catch(error){
                    this.setState({loading:false , error: error})
                }
            }
        });
    }

    newOrEditCategorias = async (sesion) =>{
        if(sesion === 0){
            this.setState({modalIsopen:true});
        }else{
            this.setState({modalIsopen:true , modalTitle:'Editar categoría' , loadingModalEdit:true, currentModal: 'categoria'});
                await this.getDetailsCategorias(sesion);
                await this.setVarEditCategorias();
            this.setState({loadingModalEdit:false});
        }
    }

    setVarEditCategorias = async () =>{   
        this.setState(
            {
                modalTitle: this.state.categoriasDetailsData.nombre,
                editSesionEstate:true,
                name: this.state.categoriasDetailsData.nombre,
            })
        this.setState({modalIsopen:true})
    }
    /*FIN MODULO DE CATEGORIAS */

    actionsFormater(cell , row){

        if(row.estatus ===1 || row.estatus === 2){
            return(
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Seleccionar
                    </button>
                    <div className="dropdown-menu">     
                        {hasPermission([11012]) &&
                            <button  title="Ver detalles" className="dropdown-item" data-toggle="modal" data-target="#modal-lg-sh"  onClick={() => {this.showSesion(row.id)}}><FontAwesomeIcon icon={faEye}/> Ver detalles</button>
                        }     
                        {hasPermission([11014]) &&
                            <button  title="Editar" className="dropdown-item" data-toggle="modal" data-target="#modal-lg"  onClick={() => {this.newOrEditSesiones(row.id)}}><FontAwesomeIcon icon={faPencilAlt}/> Editar</button>
                        }          
                        {hasPermission([11016]) && row.estatus === 1 &&
                            <button   title="Desactivar" className="dropdown-item"   onClick={() => {this.statusSesion(row.id)}}> <FontAwesomeIcon icon={faThumbsDown} /> Desactivar</button>
                        }
                        {hasPermission([11016]) && row.estatus === 2 &&
                            <button   title="Activar" className="dropdown-item"   onClick={() => {this.statusSesion(row.id)}}> <FontAwesomeIcon icon={faThumbsUp} /> Activar</button>
                        }
                    </div>
                </div>
            )
        }
        if(row.status === 1 || row.status === 2){
            return(
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Seleccionar
                    </button>
                    <div className="dropdown-menu">     
                        {hasPermission([11037]) &&
                            <button  title="Ver detalles" className="dropdown-item" data-toggle="modal" data-target="#modal-lg-sh"  onClick={() => {this.showElemento(row.id)}}><FontAwesomeIcon icon={faEye}/> Ver detalles</button>
                        }     
                        {hasPermission([11039]) &&
                            <button  title="Editar" className="dropdown-item" data-toggle="modal" data-target="#modal-lg"  onClick={() => {this.newOrEditElementos(row.id)}}><FontAwesomeIcon icon={faPencilAlt}/> Editar</button>
                        }          
                        {hasPermission([11041]) && row.status === 2 &&
                            <button   title="Activar" className="dropdown-item"   onClick={() => {this.statusElemento(row.id)}}> <FontAwesomeIcon icon={faThumbsUp} /> Activar</button>
                        }
                        {hasPermission([11041]) && row.status === 1 &&
                            <button   title="Desactivar" className="dropdown-item"   onClick={() => {this.statusElemento(row.id)}}> <FontAwesomeIcon icon={faThumbsDown} /> Desactivar</button>
                        }
                    </div>
                </div>
            )
        }
        if(row.status_tp_campaña  === 1 || row.status_tp_campaña === 2){
            return(
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Seleccionar
                    </button>
                    <div className="dropdown-menu">     
                        {hasPermission([11032]) &&
                            <button  title="Ver detalles" className="dropdown-item" data-toggle="modal" data-target="#modal-lg-sh"  onClick={() => {this.showTpCampana(row.id)}}><FontAwesomeIcon icon={faEye}/> Ver detalles</button>
                        }     
                        {hasPermission([11034]) &&
                            <button  title="Editar" className="dropdown-item" data-toggle="modal" data-target="#modal-lg"  onClick={() => {this.newOrEditTpCampana(row.id)}}><FontAwesomeIcon icon={faPencilAlt}/> Editar</button>
                        }          
                        {hasPermission([11036]) && row.status_tp_campaña === 2 &&
                            <button   title="Activar" className="dropdown-item"   onClick={() => {this.statusTpCampana(row.id)}}> <FontAwesomeIcon icon={faThumbsUp} /> Activar</button>
                        }
                        {hasPermission([11036]) && row.status_tp_campaña === 1 &&
                            <button   title="Desactivar" className="dropdown-item"   onClick={() => {this.statusTpCampana(row.id)}}> <FontAwesomeIcon icon={faThumbsDown} /> Desactivar</button>
                        }
                    </div>
                </div>
            )
        }
        if(row.status_visibilidad  === 1 || row.status_visibilidad === 2){
            return(
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Seleccionar
                    </button>
                    <div className="dropdown-menu">     
                        {hasPermission([11027]) &&
                            <button  title="Ver detalles" className="dropdown-item" data-toggle="modal" data-target="#modal-lg-sh"  onClick={() => {this.showVisibilidad(row.id)}}><FontAwesomeIcon icon={faEye}/> Ver detalles</button>
                        }     
                        {hasPermission([11029]) &&
                            <button  title="Editar" className="dropdown-item" data-toggle="modal" data-target="#modal-lg"  onClick={() => {this.newOrEditVisibilidad(row.id)}}><FontAwesomeIcon icon={faPencilAlt}/> Editar</button>
                        }          
                        {hasPermission([11031]) && row.status_visibilidad === 2 &&
                            <button   title="Activar" className="dropdown-item"   onClick={() => {this.statusVisibilidad(row.id)}}> <FontAwesomeIcon icon={faThumbsUp} /> Activar</button>
                        }
                        {hasPermission([11031]) && row.status_visibilidad === 1 &&
                            <button   title="Desactivar" className="dropdown-item"   onClick={() => {this.statusVisibilidad(row.id)}}> <FontAwesomeIcon icon={faThumbsDown} /> Desactivar</button>
                        }
                    </div>
                </div>
            )
        }
        if(row.status_subseccion  === 1 || row.status_subseccion === 2){
            return(
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Seleccionar
                    </button>
                    <div className="dropdown-menu">     
                        {hasPermission([11017]) &&
                            <button  title="Ver detalles" className="dropdown-item" data-toggle="modal" data-target="#modal-lg-sh"  onClick={() => {this.showSubsecciones(row.id)}}><FontAwesomeIcon icon={faEye}/> Ver detalles</button>
                        }     
                        {hasPermission([11019]) &&
                            <button  title="Editar" className="dropdown-item" data-toggle="modal" data-target="#modal-lg"  onClick={() => {this.newOrEditSubsecciones(row.id)}}><FontAwesomeIcon icon={faPencilAlt}/> Editar</button>
                        }          
                        {hasPermission([11021]) && row.status_subseccion === 2 &&
                            <button   title="Activar" className="dropdown-item"   onClick={() => {this.statusSubsecciones(row.id)}}> <FontAwesomeIcon icon={faThumbsUp} /> Activar</button>
                        }
                        {hasPermission([11021]) && row.status_subseccion === 1 &&
                            <button   title="Desactivar" className="dropdown-item"   onClick={() => {this.statusSubsecciones(row.id)}}> <FontAwesomeIcon icon={faThumbsDown} /> Desactivar</button>
                        }
                    </div>
                </div>
            )
        }
        if(row.estatus_categorias  === 1 || row.estatus_categorias === 2){
            return(
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Seleccionar
                    </button>
                    <div className="dropdown-menu">     
                        {hasPermission([11022]) &&
                            <button  title="Ver detalles" className="dropdown-item" data-toggle="modal" data-target="#modal-lg-sh"  onClick={() => {this.showCategorias(row.id)}}><FontAwesomeIcon icon={faEye}/> Ver detalles</button>
                        }     
                        {hasPermission([11024]) &&
                            <button  title="Editar" className="dropdown-item" data-toggle="modal" data-target="#modal-lg"  onClick={() => {this.newOrEditCategorias(row.id)}}><FontAwesomeIcon icon={faPencilAlt}/> Editar</button>
                        }          
                        {hasPermission([11026]) && row.estatus_categorias === 2 &&
                            <button   title="Activar" className="dropdown-item"   onClick={() => {this.statusCategorias(row.id)}}> <FontAwesomeIcon icon={faThumbsUp} /> Activar</button>
                        }
                        {hasPermission([11026]) && row.estatus_categorias === 1 &&
                            <button   title="Desactivar" className="dropdown-item"   onClick={() => {this.statusCategorias(row.id)}}> <FontAwesomeIcon icon={faThumbsDown} /> Desactivar</button>
                        }
                    </div>
                </div>
            )
        }
    }   
     
    handleSubseccionesChange = (nuevasSubsecciones) => {
        console.log("✅ Recibiendo subsecciones desde el hijo:", nuevasSubsecciones);
        this.setState({ subseccionesSeleccionadas: nuevasSubsecciones });
    };

    render() {
        return (           
            <CampanasSesiones
            state = {this.state}
            modalIsopen={this.state.modalIsopen}            
            newOrEditSesiones={this.newOrEditSesiones}
            onCloseModal={this.handleCloseModal}
            onOpenModal={this.handleOpenModal}
            handleChangeI={this.handleChangeI}
            currentModal={this.state.currentModal}

            handleSubseccionesChange={this.handleSubseccionesChange}

            deleteVariables = {this.deleteVariables}
            deleteSesion = {this.deleteSesion}
            handleSubmitBs={this.handleSubmitBs}
            handleSubmitElemento={this.handleSubmitElemento}
            handleSubmitTpCampana={this.handleSubmitTpCampana}
            handleSubmitVisibilidad={this.handleSubmitVisibilidad}
            handleSubmitSubsecciones={this.handleSubmitSubsecciones}
            handleSubmitCategorias={this.handleSubmitCategorias}
            modalTitle={this.state.modalTitle}
            modalTitleSesiones={this.state.modalTitleSesiones}
            modalTitleSubsecciones={this.state.modalTitleSubsecciones}
            modalTitleTpCampanas={this.state.modalTitleTpCampanas}
            modalTitleVisibilidad={this.state.modalTitleVisibilidad}
            modalTitleCategorias={this.state.modalTitleCategorias}
            modalTitleElementos={this.state.modalTitleElementos}
            onSelectTipos={this.onSelectTipos}
            onRemoveTipos={this.onRemoveTipos}
            >
            </CampanasSesiones>           
        )
    }

}