import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from 'multiselect-react-dropdown';
import 'react-dropzone-uploader/dist/styles.css';
//import Dropzone from 'react-dropzone-uploader';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Dropzone from 'react-dropzone-uploader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../styles/campanasproviders.css';
import '../../styles/Tooltip.css';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import { faUpload, faTimesCircle, faTrash, faInfoCircle, faPlus, faTimes, faFilter, faSearch, faDownload } from '@fortawesome/free-solid-svg-icons';

export default class CreatedCampanas extends Component {
    state = {
        elementosModificados: {},
        selectedZonas:[],
        selectedElementos:[],
        datefrom:'',
        noHayCupones: false,
        cadenaInfoVisible: false,
        nameInfoVisible: false,
        desdeInfoVisible: false,
        hastaInfoVisible: false,
        proveedorInfoVisible: false,
        formatoInfoVisible: false,
        zonaInfoVisible: false,
        localInfoVisible: false,
        gerenciaInfoVisible: false,
        seccionInfoVisible: false,
        tipodecampanaInfoVisible: false,
        visibilidaddecampanaInfoVisible: false,
        elementosdecampanaInfoVisible: false,
        cuposInfoVisible: false,
        fileNameAreaComercial:[],
        fileNameBasesLegales:[],
        fileAreaComercial:'',
        dataAll: {
            fileNameBasesLegales: ''
        },
        filenameareacomercialok: null,
        //fechas: [{ id: 1 }], 
        fechas: [{ id: 1, elementos: '', fecha: null, dateRange: [null, null] }],
        dateRange: [null, null],
        dateRanges: [[]],
        hayCupones: false,
        setHayCupones:false,
        isOpen: false,
        mostrarConCupones: true,
        isOpenZonas: true,
        isOpenElementos: false,
        zonasConCupo: [],
        zonasSinCupo: [],
        zonasSeleccionadas: [],
        mostrarZonasConCupones: true,
        mostrarZonasSinCupones: true,
        selectedSalas: [],
        zonasData: {}, // Asumiendo que sigues usando este objeto para almacenar la información agrupada
        zonasArray: [],
        elementosArray: [],
        showModal: false,
        modalSala: null
    }
   
    // Función para manejar el cambio de archivo para las bases legales
    handleFileChangeBasesLegales = (event) => {
        const files = event.target.files; // Obtiene los archivos seleccionados
        console.log ('objeto file ' , files );
       // this.setState({ fileBasesLegales: files }); // Actualiza el estado con los archivos seleccionados
    }

    toggleCupones = () => {
        this.setState((prevState) => ({
            hayCupones: !prevState.hayCupones
        }));
    };
    setMostrarZonasConCupones = (checked) => {
        this.setState({ mostrarZonasConCupones: checked });
    }
    
    setMostrarZonasSinCupones = (checked) => {
        this.setState({ mostrarZonasSinCupones: checked });
    }
    toggleCollapse = (section) => {
        this.setState(prevState => ({
            [section]: !prevState[section]
        }));
    };

        eliminarFecha = (index) => {
          const nuevosElementos = this.state.elementos.filter((_, idx) => idx !== index);
          this.setState({ elementos: nuevosElementos });
          this.props.setFechasAr(nuevosElementos);  // Suponiendo que setFechasAr se utiliza para actualizar el estado en el componente padre
      }

        handleChangeDateFrom = (date, index) => {
            const nuevasFechas = [...this.state.fechas];
            nuevasFechas[index].dateFrom = date;
            this.setState({ fechas: nuevasFechas });
            this.props.setFechasAr(nuevasFechas);
            
        }
    
        handleChangeDateTo = (date, index) => {
            const nuevasFechas = [...this.state.fechas];
            nuevasFechas[index].dateTo = date;
            this.setState({ fechas: nuevasFechas });
            this.props.setFechasAr(nuevasFechas);
        }
        handleChangeDateRange = dates => {
            this.setState({ dateRange: dates }); // Actualizar dateRange en el estado del componente
        };

        handleChangeElementos = (value, index) => {
            const { fechas } = this.state;
            const newFechas = [...fechas];
            newFechas[index].elementos = value;
            this.setState({ fechas: newFechas });
        };
    
        handleCheckboxChange = (idZona) => {
            // Verificar si la zona está actualmente seleccionada
            const isSelected = this.state.selectedZonas.includes(idZona);
            // Copiar el estado actual de las zonas seleccionadas
            let updatedSelectedZonas = [...this.state.selectedZonas];
            // Si la zona está seleccionada, quitarla del array de zonas seleccionadas; de lo contrario, agregarla
            if (isSelected) {
                updatedSelectedZonas = updatedSelectedZonas.filter(zonaId => zonaId !== idZona);
            } else {
                updatedSelectedZonas.push(idZona);
            }
            console.log('updatedSelectedZonas::: ', updatedSelectedZonas);
            this.setState({ selectedZonas: updatedSelectedZonas });
        };

        handleSelectAllChange = (zonas, zona_cups) => {
            console.log('zona_cups', zona_cups);
        
            const selectedZonas = this.state.selectedZonas.length === zonas.length ? [] : zonas.map(zona => zona.id);
            console.log('selectedZonas', selectedZonas);
        
            const selectedSalas = selectedZonas.length ? 
                zonas.flatMap(zona => 
                    zona.salas
                        .filter(sala => {
                            const zonaCup = zona_cups.find(cup => cup.id_zona === zona.id);
                            return zonaCup && zonaCup.salas.some(cupSala => cupSala.id === sala.id);
                        })
                        .map(sala => sala.id)
                ) : [];
        
            console.log('selectedSalas', selectedSalas);
        
            // Crear el array de zonasArray actualizado
            const updatedZonasArray = selectedZonas.length ? 
                zonas.map(zona => {
                    const zonaCup = zona_cups.find(cup => cup.id_zona === zona.id);
                    const salas = zona.salas.filter(sala => zonaCup && zonaCup.salas.some(cupSala => cupSala.id === sala.id))
                                            .map(sala => ({
                                                idSala: sala.id,
                                                nombre: sala.nombre_sap,
						cdgLocal: sala.cdg_local,
                                                direccion: sala.direccion
                                            }));
                    return {
                        id: zona.id,
                        nombre: zona.nombre,
                        salas: salas
                    };
                }).filter(zona => zona.salas.length > 0) : [];
        
            console.log('Zonas seleccionadas actualizadas:', updatedZonasArray);
        
            this.setState({ 
                selectedZonas, 
                selectedSalas,
                zonasArray: updatedZonasArray 
            });
        };


        handleElementoCheckboxChange = (elementoId, elementoName, desde, hasta) => {
            const isSelected = this.state.selectedElementos.includes(elementoId);
            let updatedSelectedElementos = [...this.state.selectedElementos];
            let updatedElementosArray = [...this.state.elementosArray]; // Nuevo array para almacenar la información de los elementos
        
            if (isSelected) {
                updatedSelectedElementos = updatedSelectedElementos.filter(id => id !== elementoId);
            } else {
                updatedSelectedElementos.push(elementoId);
            }
        
            // Buscar el elemento correspondiente en el array o crear una nueva entrada si no existe
            let elementoIndex = updatedElementosArray.findIndex(elemento => elemento.id === elementoId);
            if (elementoIndex === -1) {
                updatedElementosArray.push({
                    id: elementoId,
                    nombre: elementoName,
                    desde: desde,
                    hasta: hasta
                });
            } else {
                // Si el elemento ya existe, actualizar sus datos
                updatedElementosArray[elementoIndex] = {
                    id: elementoId,
                    nombre: elementoName,
                    desde: desde,
                    hasta: hasta
                };
            }
        
            this.setState({ 
                selectedElementos: updatedSelectedElementos, 
                elementosArray: updatedElementosArray // Actualizar el estado con el nuevo array de elementos
            }, () => {
                console.log("Elementos seleccionados actualizados:", this.state.elementosArray);
            });
        };

        handleSalaCheckboxChange = (idZona, zonaName, idSala, salaName, saleDireccion) => {
            const isSelected = this.state.selectedSalas.includes(idSala);
            let updatedSelectedSalas = [...this.state.selectedSalas];
            let updatedSelectedZonas = [...this.state.selectedZonas];
            let updatedZonasArray = [...this.state.zonasArray]; // Nuevo array para almacenar la información de las zonas
        
            if (isSelected) {
                updatedSelectedSalas = updatedSelectedSalas.filter(salaId => salaId !== idSala);
                if (!this.state.zonasArray.some(zona => zona.id === idZona && zona.salas.some(sala => sala.idSala !== idSala))) {
                    updatedSelectedZonas = updatedSelectedZonas.filter(zonaId => zonaId !== idZona);
                }
            } else {
                updatedSelectedSalas.push(idSala);
                if (!updatedSelectedZonas.includes(idZona)) {
                    updatedSelectedZonas.push(idZona);
                }
            }
        
            // Buscar la zona correspondiente en el array o crear una nueva entrada si no existe
            let zonaIndex = updatedZonasArray.findIndex(zona => zona.id === idZona);
            if (zonaIndex === -1) {
                updatedZonasArray.push({
                    id: idZona,
                    nombre: zonaName,
                    salas: []
                });
                zonaIndex = updatedZonasArray.length - 1;
            }
        
            // Actualizar la lista de salas de la zona correspondiente
            if (isSelected) {
                updatedZonasArray[zonaIndex].salas = updatedZonasArray[zonaIndex].salas.filter(sala => sala.idSala !== idSala);
            } else {
                updatedZonasArray[zonaIndex].salas.push({
                    idSala: idSala,
                    nombre: salaName,
                    direccion: saleDireccion
                });
            }
        
            this.setState({ 
                selectedSalas: updatedSelectedSalas, 
                selectedZonas: updatedSelectedZonas,
                zonasArray: updatedZonasArray // Actualizar el estado con el nuevo array de zonas
            }, () => {
                console.log("Zonas seleccionadas actualizadas:", this.state.zonasArray);
            });
        };

        agregarElemento = () => {
            const nuevosElementos = [...this.state.elementos];
            nuevosElementos.push({ selectedValues: [], dateRange: [null, null] });
            this.setState({ elementos: nuevosElementos });
            this.props.setFechasAr(nuevosElementos);
            // Puedes realizar cualquier acción adicional aquí si es necesario
        };

        setMostrarConCupones = (value) => {
            console.log('sin cupos', value)
            this.setState({ mostrarConCupones: value });
        };

        handleDownloadClick = () => {
            // Aquí puedes pasar el ID de la campaña como argumento
            this.props.handleDownload(this.props.state.responseData?.data?.campana.id);
          };

        handleSearchClick = (sala) => {
            console.log(sala);
            this.setState({ showModal: true, modalSala: sala });
        }

        handleCloseModal = () => {
            this.setState({ showModal: false, modalSala: null });
        }

        formatDateString = (dateString) => {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.getMonth() + 1; // getMonth() devuelve el mes base 0 (enero = 0), sumamos 1 para obtener el mes real
            return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}`;
        }

        handleInputChange = (elementoId, value) => {
            console.log('Modificando elemento:', elementoId, 'con valor:', value);
            this.setState(prevState => ({
                elementosModificados: {
                    ...prevState.elementosModificados,
                    [elementoId]: value
                }
            }));
        }
    render() {
        
        const dataAll = this.props.state; 
        const campana = dataAll.responseData?.data?.campana || null
        const formato = dataAll.responseData?.data?.formato || null
        const propsInt = this.props;
        const currentDate = new Date();
        const { fechas } = this.state;
        const {dateRanges, zonasSinCupo, zonasConCupo, mostrarZonasSinCupones, mostrarZonasConCupones, selectedZonas, selectedSalas, mostrarConCupones, zonasFiltradas, isOpenZonas, isOpenElementos, showModal, modalSala  } = this.state;
        
            const zonas = !mostrarConCupones ? 
            (dataAll.responseData && dataAll.responseData.data && dataAll.responseData.data.zona_sin_cups ? 
                dataAll.responseData.data.zona_sin_cups.map(zona => ({ ...zona, conCupos: false })) :
                []) : 
            (dataAll.responseData && dataAll.responseData.data && dataAll.responseData.data.zona_cups ? 
                dataAll.responseData.data.zona_cups.map(zona => ({ ...zona, conCupos: true })) :
                []);
        
                console.log('zonas:: ', zonas);
            const filteredZonas = zonas.filter(zona => this.state.selectedZonas.includes(zona.id_zona));
                console.log('filteredZonas:: ', filteredZonas);
          
            const zonasSeleccionadas = this.state.zonasArray;
            const elementosSelected = dataAll.responseData && dataAll.responseData.data ? dataAll.responseData.data.elementos :  [];
            const elementos = dataAll?.responseData?.data?.elementos || [];
        
            const columns = [
                {
                    dataField: 'index',
                    text: 'N°',
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                        const { page, sizePerPage } = formatExtraData;
                        const index = ((page - 1) * sizePerPage) + rowIndex + 1;
                        return String(index).padStart(2, '0');
                    },
                    formatExtraData: {
                        page: 1,
                        sizePerPage: 10
                    },
                    headerStyle: { width: '10%' }
                },
                {
                    dataField: 'elementos',
                    text: 'Elementos de campaña',
                    headerStyle: { width: '70%' }
                },
                {
                    dataField: 'n_cupos',
                    text: 'Cupos',
                    headerStyle: { width: '20%' },
                    formatter: (cell, row) => {
                        console.log('valor row', row);
                        // Usar el ID único del elemento como clave
                        const uniqueId = row.id_elemento_cupo || row.elementos; // Asegúrate de tener un identificador único
                        console.log('valor uniqueId', uniqueId);
                        const currentValue = this.state.elementosModificados[uniqueId] !== undefined 
                            ? this.state.elementosModificados[uniqueId] 
                            : cell;
    
                        return (
                            <input 
                                type="number" 
                                className="form-control text-center" 
                                defaultValue={currentValue}
                                min="0"
                                onKeyPress={(e) => {
                                    // Permitir solo números
                                    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    // Asegurarse de que el valor sea un número o vacío
                                    if (newValue === '' || /^[0-9]*$/.test(newValue)) {
                                        this.handleInputChange(uniqueId, newValue);
                                    }
                                }}
                            />
                        );
                    }
                }
            ];
    
            const paginationOptions = {
                custom: true,
                totalSize: elementos.length,
                sizePerPage: 10,
                firstPageText: 'Primera',
                prePageText: 'Anterior',
                nextPageText: 'Siguiente',
                lastPageText: 'Última',
                pageStartIndex: 1,
                hideSizePerPage: true,
                hidePageListOnlyOnePage: true,
                onPageChange: (page, sizePerPage) => {
                    // Opcional: hacer algo cuando cambia la página
                    console.log('Cambio de página:', page);
                }
            };

        return (            
                <div className="col-10 tabs cont-height">
                    <h2 className="mt-4 mb-4">Cupos disponibles</h2>

                    <div className="white-container">
                        <h3 className="mb-4"> <FontAwesomeIcon icon={faFilter} style={{ fontSize: '0.75em' }}/> Buscar</h3>
                        <div className="form-group row">
                            <div className="col-sm-12 col-lg-3 mb-4">
                                <Multiselect
                                singleSelect
                                    options={dataAll.listaCadenasCreate} 
                                    onSelect={propsInt.onSelectCadenas} 
                                    onRemove={propsInt.onRemovCadenas}
                                    selectedValues={dataAll.listaCadenasSeleccionadas} 
                                    displayValue="nombre"
                                    placeholder="Cadena"
                                />
                                {dataAll.errorsForm.cadena.length > 0 && <span className='error'>{dataAll.errorsForm.cadena}</span>}
                            </div>

                            <div className="col-sm-12 col-lg-3 mb-4">
                            <Multiselect
                                options={dataAll.listaTipoSalas} 
                                onSelect={propsInt.onSelectTipoSalas} 
                                onRemove={propsInt.onRemoveTipoSalas}
                                selectedValues={dataAll.TipoSalaseleccionada} 
                                displayValue="nombre"
                                placeholder="Formato"
                            />
                            {dataAll.errorsForm.salas.length > 0 && <span className='error error-class-i'>{dataAll.errorsForm.salas}</span>}
                            </div>

                            <div className="col-sm-12 col-lg-3 mb-4">
                                <div className="multiselect-container-admin position-relative">
                                    <Multiselect
                                        options={dataAll.listaZonas}
                                        onSelect={propsInt.onSelectZonas}
                                        onRemove={propsInt.onRemovZonas}
                                        selectedValues={dataAll.listZonasSeleted}
                                        displayValue="nombre"
                                        placeholder="Zonas"
                                        className="multiselect-custom"
                                        showCheckbox
                                    />
                                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                    {dataAll.errorsForm.zona.length > 0 && <span className='error error-class-i'>{dataAll.errorsForm.zona}</span>}
                                </div>
                            </div>

                            <div className="col-sm-12 col-lg-3 mb-4">
                                <div className="multiselect-container-admin">
                                    <Multiselect
                                        options={dataAll.listaSalas.map(option => ({ ...option, display_nombre_sap: option.display_nombre_sap.toUpperCase() }))} 
                                        onSelect={propsInt.onSelectSalas} 
                                        onRemove={propsInt.onRemovSalas}
                                        selectedValues={dataAll.listSalasSeleted} 
                                        displayValue="display_nombre_sap"
                                        placeholder="Locales"
                                        showCheckbox
                                        style={{
                                            chips: {
                                              'white-space': 'normal'
                                            }
                                          }}
                                    />
                                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                    {dataAll.errorsForm.salas.length > 0 && <span className='error error-class-i'>{dataAll.errorsForm.salas}</span>}
                                </div>
                            </div>

                            <div className="col-sm-12 col-lg-3 mb-4">
                                <Multiselect
                                    singleSelect
                                    options={dataAll.espaciosDatas} 
                                    onSelect={propsInt.onSelectEspacios} 
                                    onRemove={propsInt.onRemoveEspacios}
                                    selectedValues={dataAll.espaciosSeleccionada} 
                                    displayValue="nombre" 
                                    placeholder="Sección"
                                />
                                {dataAll.errorsForm.espacio.length > 0 && <span className='error error-class-i'>{dataAll.errorsForm.espacio}</span>}
                            </div>

                            <div className="col-sm-12 col-lg-3 mb-4">
                                <Multiselect
                                    singleSelect
                                    options={dataAll.listaSecciones} 
                                    onSelect={propsInt.onSelectSecciones} 
                                    onRemove={propsInt.onRemovSecciones}
                                    selectedValues={dataAll.listSeccionesSeleted} 
                                    displayValue="nombre"
                                    placeholder="Sub-sección"
                                />
                                {dataAll.errorsForm.seccion.length > 0 && <span className='error error-class-i'>{dataAll.errorsForm.seccion}</span>}
                            </div>

                            <div className="col-sm-12 col-lg-3 mb-4">
                                <Multiselect
                                    singleSelect
                                    options={dataAll.listaSubSecciones} 
                                    onSelect={propsInt.onSelectSubSecciones} 
                                    onRemove={propsInt.onRemovSubSecciones}
                                    selectedValues={dataAll.listSubSeccionesSeleted} 
                                    displayValue="nombre"
                                    placeholder="Categoría"
                                />
                                {dataAll.errorsForm.sub_seccion.length > 0 && <span className='error error-class-i'>{dataAll.errorsForm.sub_seccion}</span>}
                            </div>

                            <div className="col-sm-12 col-lg-3 mb-4">
                                <Multiselect
                                        singleSelect
                                        options={dataAll.listaVisibilidadCampana} 
                                        onSelect={propsInt.onSelectVisibilidadCampana} 
                                        onRemove={propsInt.onRemoveVisibilidadCampana}
                                        selectedValues={dataAll.listVisibilidadCampanaSeleted} 
                                        displayValue="name" 
                                        placeholder="Visibilidad campaña"
                                    />
                                {dataAll.errorsForm.visibilidadCampana.length > 0 && <span className='error error-class-i'>{dataAll.errorsForm.visibilidadCampana}</span>}
                            </div>

                            <div className="col-sm-12 col-lg-3 mb-4">
                                <Multiselect
                                        singleSelect
                                        options={dataAll.listaTpCampana} 
                                        onSelect={propsInt.onSelectTpCampana} 
                                        onRemove={propsInt.onRemovTpCampana}
                                        selectedValues={dataAll.listTpCampanaSeleted} 
                                        displayValue="name" 
                                        placeholder="Tipo de campaña"
                                    />
                                {dataAll.errorsForm.tpCampana.length > 0 && <span className='error error-class-i'>{dataAll.errorsForm.tpCampana}</span>}
                            </div>

                            <div className="col-sm-12 col-lg-2">
                                <button className="btn btn-outline-primary btn-block" onClick={propsInt.handleClearFilters}>
                                    Borrar filtros
                                </button>
                            </div>
                            <div className="col-sm-12 col-lg-2">
                                <button className="btn btn-primary btn-block" onClick={propsInt.handleSubmitBs}>Buscar</button>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="mt-4 mb-4"><strong>Resultados</strong></h3>
                            {
                                campana && (
                                    <h4>
                                        <a href="#" className="btn btn-outline-primary download-button" onClick={this.handleDownloadClick}>
                                            Descargar <FontAwesomeIcon icon={faDownload} style={{ fontSize: '17px', fontWeight: 'lighter' }} />
                                        </a>
                                    </h4>
                                )
                            }
                        </div>

                        {!dataAll.responseData ? (
                    <div className="no-cupons">
                        <h3>
                            <img alt='Cencocheck' src={process.env.PUBLIC_URL + '/dist/img/ic-nocupons.svg'}/>
                            ¡No hay cupos aún!
                        </h3>
                        <p>Aquí es donde podrás ver los cupos disponibles.</p>
                    </div>
                ) : (
                    <div className="">
                    <PaginationProvider 
                        pagination={paginationFactory({
                            custom: true,
                            totalSize: elementos.length,
                            sizePerPage: 10,
                            firstPageText: 'Primera',
                            prePageText: 'Anterior',
                            nextPageText: 'Siguiente',
                            lastPageText: 'Última',
                            pageStartIndex: 1,
                            hideSizePerPage: true,
                            hidePageListOnlyOnePage: true
                        })}
                    >
                        {({ paginationProps, paginationTableProps }) => {
                            columns[0].formatExtraData = {
                                page: paginationProps.page || 1,
                                sizePerPage: 10
                            };
    
                            return (
                                <div>
                                    <BootstrapTable
                                        bootstrap4
                                        keyField={row => row.id || row.elementos} // Usar el mismo identificador único
                                        data={elementos}
                                        columns={columns}
                                        {...paginationTableProps}
                                        classes="table table-bordered"
                                        rowClasses={(row, rowIndex) => 
                                            rowIndex % 2 === 0 ? 'bg-light' : ''
                                        }
                                        noDataIndication="No hay datos disponibles"
                                    />
                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        <PaginationListStandalone {...paginationProps} />
                                        <button 
                                            className="btn btn-primary"
                                            onClick={() => {
                                                const elementosActualizados = elementos.map(elemento => {
                                                    const uniqueId = elemento.id_elemento_cupo || elemento.elementos;
                                                    console.log('elemento unico', uniqueId);
                                                    return {
                                                        ...elemento,
                                                        n_cupos: this.state.elementosModificados[uniqueId] !== undefined 
                                                            ? this.state.elementosModificados[uniqueId] 
                                                            : elemento.n_cupos
                                                    };
                                                });
                                                console.log('elementos actualizados', elementosActualizados);
                                                propsInt.handleCupoChange(elementosActualizados);
                                            }}
                                        >
                                            Actualizar cupos
                                        </button>
                                    </div>
                                </div>
                            );
                        }}
                    </PaginationProvider>
                </div>
                )}

                    </div>
            </div>
        )
    }
}
